import { FetchService } from "@/shared";
import { privacyEndpoints, type PrivacyEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class PrivacyRepository {
  private readonly endpoints: PrivacyEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = privacyEndpoints;
  }

  public fethPrivacy() {
    const url = this.endpoints.getOne;

    return this.fethService.get({ url })
    .then(data => dto.PrivacyResponseDto.parseAsync(data));
  }

  public fethEditPrivacy( payload : dto.EditPrivacyDto ) {
    const url = this.endpoints.edit;

    return this.fethService.put({ url, payload })
    .then(data => dto.PrivacyResponseDto.parseAsync(data));
  }


}
