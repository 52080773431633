import { z } from "zod";

export const RegionsParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
});

export const CreateRegionSchema = z.object({
  name: z.string().min(1, { message: "Введите название" }),
});

export type RegionsParamsDto = z.infer<typeof RegionsParamsSchema>;
export type CreateRegionDto = z.infer<typeof CreateRegionSchema>;
