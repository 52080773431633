import { FetchService } from "@/shared";
import { bannersEndpoints, type BannerEndpointsType } from "./endpoints";
import * as dto from "../dto";


export class BannerRepository {
 private readonly endpoints: BannerEndpointsType;
 
  constructor(private readonly fethService: FetchService) {   
    this.endpoints = bannersEndpoints;
  }

  public fetchBanners(params: dto.BannersParamsDto) {
    const url = this.endpoints.getList;

    return this.fethService.get({ url, params })
    .then(data => dto.BannersResponseDto.parseAsync(data));
  }

  public fethBanner(id: string) {
    const url = this.endpoints.getOne(id);

    return this.fethService.get({ url })
    .then(data => dto.CurrentBannerResponseDto.parseAsync(data));
  }

  public fetchCreateBanner(payload : dto.CreateBannerDto) {
    const url = this.endpoints.create;

    return this.fethService.post({ url, payload })
    .then(data => dto.CurrentBannerResponseDto.parseAsync(data));
  }

  public fethEditBanners({ id, payload }: { id: string, payload: dto.CreateBannerDto }) {
    const url = this.endpoints.edit(id);

    return this.fethService.put({ url, payload })
    .then(data => dto.CurrentBannerResponseDto.parseAsync(data));
  }
  
  public fethRemoveBanner(id: string) {
    const url = this.endpoints.remove(id);
    
    return this.fethService.delete({ url })
    .then(data => dto.CurrentBannerResponseDto.parseAsync(data));
  }

  public fethUpdateImage({id , file}: { id: string, file: File }) {
    const url = this.endpoints.bannerImage(id);

    const payload = new FormData();
    payload.append('file', file)
    
    return this.fethService.put({ url, payload })
    .then(data => dto.CurrentBannerResponseDto.parseAsync(data));
  }

}
