import { z } from "zod";

export const EditSmtpSchema = z.object({
  smtpHost: z.string().min(1, { message: 'Введите Smtp-хост' }),
  smtpPort: z.string().min(1, { message: 'Введите Smtp-порт' }),
  smtpUsername: z.string().min(1, { message: 'Введите имя-Smtp' }),
  smtpPassword: z.string().min(1, { message: 'Введите Пароль' }),
});


export type EditSmtpPayloadDto = z.infer<typeof EditSmtpSchema>;
