export type LanguageEndpointsType = {
  create: string;
  getList: string;
  getOne: (id: string) => string;
  remove: (id: string) => string;
  edit: (id: string) => string;
};

export const languagesEndpoints: LanguageEndpointsType = {
  create: "languages",
  getList: "languages",
  getOne: (id: string) => `languages/${id}`,
  remove: (id: string) => `languages/${id}`,
  edit: (id: string) => `languages/${id}`,
};
