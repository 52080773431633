import { RegionModel } from "../schema/schema";
import { regionStore, type RegionStoreType } from "./store";

export class RegionAdapter {
  public store: RegionStoreType;
  constructor() {
    this.store = regionStore;
  }

  public setRegionsList(categorys: RegionModel[]) {
    return this.store.setState({ regionList: categorys });
  }

  public setCurrentRegion(category: RegionModel) {
    return this.store.setState({ currentRegion: category });
  }

  public filterById(id: string) {
    const prevList = this.store.getState().regionList;

    return this.store.setState({
      regionList: prevList.filter((item) => item.id !== Number(id)),
    });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ currentCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }
}
