import { createStore } from "@/shared/service/create-store";
import { BannerInitState } from "../schema/schema";


const initState: BannerInitState = {
  bannersList: [],
  currentBanner: null,
  loadingSubmit: false,
  currentCount: 0,
};

export const bannerStore = createStore({ initState });

export type BannerStoreType = typeof bannerStore;