import { templateStore, templateStoreType } from "./store";
import * as schema from "../schema/schema";


export class TemplateAdapter {
  public store: templateStoreType
  constructor() {
    this.store = templateStore;
  }

  public setTemplates(templates: schema.TemplateModel[]) {
    return this.store.setState({ templates });
  }

  public setCurrentTemplate(template: schema.TemplateModel) {
    return this.store.setState({ currentTemplate: template });
  }

  public setKeys(keys: schema.KeyModel[]) {
    return this.store.setState({ keys });
  }

  public filterById(id: string) {
    const templatesList = this.store.getState().templates;

    return this.store.setState({
      templates: templatesList.filter((item) => item.id !== Number(id)),
    });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ totalCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }
}
