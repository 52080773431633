import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { ManagerSchema } from "../schema/schema";

const ManagersResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  regions: z.array(ManagerSchema),
});

export const ManagersResponseDto = createResponseSchema(ManagersResponseSchema);
export const CurrentManagerResponseDto = createResponseSchema(ManagerSchema);
// export const RemoveRegionResponseDto = createResponseSchema(z.number());
