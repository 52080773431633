import { createStore } from "@/shared/service/create-store";
import * as schema from "../schema/schema";

const initState: schema.TemplateInitState = {
  templates: [],
  currentTemplate: null,
  keys: [],
  loadingSubmit: false,
  totalCount: 0,
};

export const templateStore = createStore({ initState });

export type templateStoreType = typeof templateStore;