import { z } from "zod";

const SubCategorySchema = z.array(z.object({
  category: z.array(z.object({
    id: z.number(),
    title: z.string(),
    img: z.string().nullable(),
    parent_category: z.number().nullable(),
    date_create: z.string().nullable(),
    date_update: z.string().nullable(),
    date_delete: z.string().nullable(),
  })).optional(),
  id: z.number(),
  title: z.string(),
  img: z.string().nullable(),
  parent_category: z.number().nullable(),
  date_create: z.string().nullable(),
  date_update: z.string().nullable(),
  date_delete: z.string().nullable(),
}))

export const CategorySchema = z.object({
  id: z.number(),
  title: z.string(),
  img: z.string().nullable(),
  parent_category: z.number().nullable(),
  date_create: z.string().nullable(),
  date_update: z.string().nullable(),
  date_delete: z.string().nullable(),
  category: SubCategorySchema.optional(),
});

const CategoryInitStateSchema = z.object({
  categoryList: z.array(CategorySchema),
  parentCategory: z.array(CategorySchema),
  subParrentCategorys: z.array(CategorySchema),
  currentCategory: CategorySchema.nullable(),
  loadingSubmit: z.boolean(),
  parentLoading: z.boolean(),
  subParentLoading: z.boolean(),
  currentCount: z.number(),
});

export type CategoryModel = z.infer<typeof CategorySchema>;
export type CategoryInitState = z.infer<typeof CategoryInitStateSchema>;
