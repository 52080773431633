export type TemplateEndpointsType = {
  getAllTemplates: string;
  createTemplates: string;
  templateInfo: (id: string) => string;
  editTemplate: (id: string) => string;
  removeTemplate: (id: string) => string;
  getTemplateKeys: string;
};

export const templateEndpoints: TemplateEndpointsType = {
  getAllTemplates: "template",
  createTemplates: "template",
  templateInfo: (id: string) => `template/${id}`,
  getTemplateKeys: "template/keys",
  editTemplate: (id: string) => `template/${id}`,
  removeTemplate: (id: string) => `template/${id}`,
};
