import { SmtpModel } from "../schema/schema";
import { smtpStore, smtpStoreType } from "./store";

export class SmtpAdapter {
  public store: smtpStoreType
  constructor() {
    this.store = smtpStore;
  }

  public setSmtp(smtp: SmtpModel) {
    return this.store.setState({ smtp });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }
}
