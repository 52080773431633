import { FetchService } from "@/shared";
import { reviewsEndpoints, type ReviewsEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class ReviewsRepository {
  private readonly endpoints: ReviewsEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = reviewsEndpoints;
  }

  public fethReviewsList(params: dto.ReviewsParamsDto) {
    const url = this.endpoints.getList;

    return this.fethService.get({ url, params })
    .then((data) => dto.ReviewsResponseDto.parseAsync(data));
  }

  public fethReview(id: string) {
    const url = this.endpoints.getOne(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.CurrentReviewsyResponseDto.parseAsync(data));
  }

  public fetchCreateReviews(payload: dto.CreateReviewsDto) {
    const url = this.endpoints.create;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.CurrentReviewsyResponseDto.parseAsync(data));
  }

  public fethEditReviews({
    id,
    payload,
  }: {
    id: string;
    payload: dto.CreateReviewsDto;
  }) {
    const url = this.endpoints.edit(id);

    return this.fethService
      .put({ url, payload })
      .then((data) => dto.CurrentReviewsyResponseDto.parseAsync(data));
  }

  public fethRemoveReviews(id: string) {
    const url = this.endpoints.remove(id);

    return this.fethService
      .delete({ url })
      .then((data) => dto.RemoveReviewsResponseDto.parseAsync(data));
  }
}
