import { FetchService } from "@/shared";
import { categoryEndpoints, type CategoryEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class CategoryRepository {
  private readonly endpoints: CategoryEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = categoryEndpoints;
  }

  public fethCategoryes(params: dto.CategoryesParamsDto) {
    const url = this.endpoints.getList;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.CategoryResponseDto.parseAsync(data));
  }

  public fethCategory(id: string) {
    const url = this.endpoints.getOne(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.CurrentCategoryResponseDto.parseAsync(data));
  }

  public fetchCreateCategory(payload: dto.CreateCategoryDto) {
    const url = this.endpoints.create;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.CurrentCategoryResponseDto.parseAsync(data));
  }

  public fethEditCategory({
    id,
    payload,
  }: {
    id: string;
    payload: dto.CreateCategoryDto;
  }) {
    const url = this.endpoints.edit(id);

    return this.fethService
      .put({ url, payload })
      .then((data) => dto.CurrentCategoryResponseDto.parseAsync(data));
  }

  public fethRemoveCategory(id: string) {
    const url = this.endpoints.remove(id);

    return this.fethService
      .delete({ url })
      .then((data) => dto.CurrentCategoryResponseDto.parseAsync(data));
  }

  public fethUpdateCategoryImage({ id, file }: { id: string; file: File }) {
    const url = this.endpoints.editCategoryImage(id);

    const payload = new FormData();
    payload.append("file", file);

    return this.fethService
      .put({ url, payload })
      .then((data) => dto.CurrentCategoryResponseDto.parseAsync(data));
  }

  public fetchTree() {
    const url = this.endpoints.getTree;

    return this.fethService
      .get({ url })
      .then((data) => dto.TreeCategoryResponseDto.parseAsync(data));
  }

  public fetchParentCategory(id: string) {
    const url = this.endpoints.getParentCategory(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.TreeCategoryResponseDto.parseAsync(data));
  }
}
