import { z } from "zod";

export const BannerSchema = z.object({
  id: z.number(),
  title: z.string(),
  url: z.string().nullable(),
  img: z.string(),
  orientation: z.string(),
  date_create: z.string().nullable(),
  date_update: z.string().nullable(),
  date_delete: z.string().nullable(),
  region_id: z.array(z.object({
    id: z.number(),
    banner_id: z.number(),
    region_id: z.number(),
  })).nullable(),
});

const BannerInitStateSchema = z.object({
  bannersList: z.array(BannerSchema),
  currentBanner: BannerSchema.nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
});

export type BannerModel = z.infer<typeof BannerSchema>;
export type BannerInitState = z.infer<typeof BannerInitStateSchema>;
