import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { ReviewsSchema } from "../schema/schema";

const ReviewsResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  feedback: z.array(ReviewsSchema),
});

export const ReviewsResponseDto = createResponseSchema(ReviewsResponseSchema);
export const CurrentReviewsyResponseDto = createResponseSchema(ReviewsSchema);
export const RemoveReviewsResponseDto = createResponseSchema(z.number());
