import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { CategorySchema } from "../schema/schema";

const CategoryResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  regions: z.array(CategorySchema),
});

const TreeCategoryResponseSchema = z.array(CategorySchema);

export const TreeCategoryResponseDto = createResponseSchema(TreeCategoryResponseSchema);
export const CategoryResponseDto = createResponseSchema(CategoryResponseSchema);
export const CurrentCategoryResponseDto = createResponseSchema(CategorySchema);

export type CurrentCategoryResponse = z.infer<typeof CurrentCategoryResponseDto>;