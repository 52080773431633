import { z } from "zod";

export const ReviewsParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
  item_id: z.string().optional(),
});

export const CreateReviewsSchema = z.object({
  item_id: z.number(),
  rate: z.number().min(1, { message: "Введите от 1 до 5" }).max(5, { message: "Введите от 1 до 5" }),
  name: z.string().min(1, { message: "Введите название" }),
  advantage: z.string().min(1, { message: "Введите преимущество" }),
  disadvantage: z.string().min(1, { message: "Введите недостаток" }),
  comment: z.string().min(1, { message: "Введите комментарий" }),
});

export type ReviewsParamsDto = z.infer<typeof ReviewsParamsSchema>;
export type CreateReviewsDto = z.infer<typeof CreateReviewsSchema>;
