import { FetchService } from "@/shared";
import { templateEndpoints, type TemplateEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class TemplateRepository {
  private readonly endpoints: TemplateEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = templateEndpoints;
  }

  public fethGetTemplates(params: dto.GetTemplatesParamsDto) {
    const url = this.endpoints.getAllTemplates;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.TemplatesResponseDto.parseAsync(data));
  }

  public fethTemplate(id: string) {
    const url = this.endpoints.templateInfo(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.TemplateResponseDto.parseAsync(data));
  }

  public fethTemplateKeys() {
    const url = this.endpoints.getTemplateKeys;

    return this.fethService.get({ url })
    .then(data => dto.KeysResponseDto.parseAsync(data));
  }

  public fetchCreateTemplate(payload: dto.CreateTemplatePayloadDto) {
    const url = this.endpoints.createTemplates;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.TemplateResponseDto.parseAsync(data));
  }

  public fethEditTemplate({ id, payload }: { id: string; payload: dto.CreateTemplatePayloadDto }) {
    const url = this.endpoints.editTemplate(id);

    return this.fethService
      .put({ url, payload })
      .then((data) => dto.TemplateResponseDto.parseAsync(data));
  }

  public fethRemoveTemplate(id: string) {
    const url = this.endpoints.removeTemplate(id);

    return this.fethService
      .delete({ url })
      .then((data) => dto.TemplateRemoveResponseDto.parseAsync(data));
  }
}
