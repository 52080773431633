import { FetchService } from "@/shared";
import { AuthRepository } from "../repositiry/repository";
import * as dto from "../dto";


export class AuthService {
  private readonly repository: AuthRepository;

  constructor() {
    this.repository = new AuthRepository(new FetchService());
  }

  public authorization(payload: dto.AuthorizationDto) {
    return this.repository.fethAuthorization(payload);
  }

  public fethRefreshToken() {
    return this.repository.fethRefreshToken();
  }

  public getProfile() {
    return this.repository.fethProfile();
  }

  public editAvatar(payload: FormData) {
    return this.repository.fethChangeAvatar(payload);
  }

  public editProfile(payload: dto.EditProfileAdminDto) {
    return this.repository.fethEditProfile(payload);
  }

  public editPassword(payload: dto.EditProfileAdminPasswordDto) {
    return this.repository.fethChangePassword(payload);
  }

  public recoveryPassword(payload: dto.RecoveryPasswordSchemaDto) {
    return this.repository.fethRecoveryPassword(payload);
  }
}
