import { z } from "zod";

export const AuthorizationSchema = z.object({
  username: z.string().min(1, { message: "Введите логин" }),
  password: z.string().min(8, { message: "Введите не менее 8 символов" }),
});

export const EditProfileAdminSchema = z.object({
  // username: z.string().min(1, { message: "Введите логин" }),
  email: z.string().email("Неверный формат почты"),
});

export const EditProfileAdminPasswordSchema = z.object({
  password: z.string().min(8, { message: "Введите не менее 8 символов" }),
});

export const RecoveryPasswordSchema = z.object({
  email: z.string().email("Неверный формат почты"),
  link: z.string(),
});


export type EditProfileAdminDto = z.infer<typeof EditProfileAdminSchema>;
export type EditProfileAdminPasswordDto = z.infer<typeof EditProfileAdminPasswordSchema>;
export type AuthorizationDto = z.infer<typeof AuthorizationSchema>;
export type RecoveryPasswordSchemaDto = z.infer<typeof RecoveryPasswordSchema>;
