import { FetchService } from "@/shared";
import { smtpEndpoints, type MstpEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class SmtpRepository {
  private readonly endpoints: MstpEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = smtpEndpoints;
  }

  public fethGetSmpt() {
    const url = this.endpoints.getSmtp;

    return this.fethService.get({ url })
    .then((data) => dto.SmtpResponseDto.parseAsync(data));
  }

  public fethEditSmtp(payload: dto.EditSmtpPayloadDto) {
    const url = this.endpoints.editSmtp;

    return this.fethService.put({ url, payload })
    .then((data) => dto.SmtpResponseDto.parseAsync(data));
  }
}
