import { NotFound } from '@/widgets/not-found/NotFound';

const NotFoundPage = () => {
   
return (
     <NotFound />
);
};

export { NotFoundPage };
