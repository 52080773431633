import { FetchService } from "@/shared";
import { regionEndpoints, type RegionsEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class RegionsRepository {
  private readonly endpoints: RegionsEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = regionEndpoints;
  }

  public fethRegions(params: dto.RegionsParamsDto) {
    const url = this.endpoints.getList;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.RegionsResponseDto.parseAsync(data));
  }

  public fethRegion(id: string) {
    const url = this.endpoints.getOne(id);

    return this.fethService.get({ url })
    .then(data => dto.CurrentRegionResponseDto.parseAsync(data));
  }

  public fetchCreateRegion(payload: dto.CreateRegionDto) {
    const url = this.endpoints.create;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.CurrentRegionResponseDto.parseAsync(data));
  }

  public fethEditRegion({ id, payload }: { id: string, payload: dto.CreateRegionDto }) {
    const url = this.endpoints.edit(id);

    return this.fethService.put({ url, payload })
    .then(data => dto.CurrentRegionResponseDto.parseAsync(data));
  }

  public fethRemoveRegion(id: string) {
    const url = this.endpoints.remove(id);

    return this.fethService.delete({ url })
    .then(data => dto.RemoveRegionResponseDto.parseAsync(data));
  }
}
