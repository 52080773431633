import { PrivacyService } from "../service/service";
import { PrivacyAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

class PrivacyController {
  constructor(
    private readonly privacyService: PrivacyService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: PrivacyAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getPrivacy() {
    this.loadingController.activeFetchLoading();
    this.privacyService
      .fethPrivacy()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setPrivacy(response.data.privacy);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public editPrivacy(payload: dto.EditPrivacyDto) {
    this.storeAdapter.setLoadingSubmit();
    this.privacyService
      .fethEditPrivacy(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setPrivacy(response.data.privacy);
          this.notificationService.activeNotification({
            status: 'success',
            message: 'Пользовательское соглашение успешно сохранено'
          })
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }
}

export const privacyController = new PrivacyController(
  new PrivacyService(),
  new NotificationService(),
  new PrivacyAdapter(),
  new LoadingController()
);
