import { createStore } from "@/shared/service/create-store";
import { ManagerInitState } from "../schema/schema";

const initState: ManagerInitState = {
  managerList: [],
  currentManager: null,
  loadingSubmit: false,
  currentCount: 0,
};

export const managerStore = createStore({ initState });

export type ManagerStoreType = typeof managerStore;
