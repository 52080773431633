import { CONFIG_APP } from "@/shared/config";
import { AuthService } from "../service/service";
import { AuthAdapter } from "../state/adapter";
import { NotificationService } from "@/shared";
import {
  CookieService,
  CookieServiceType,
} from "@/shared/service/cookie/CookieService";
import * as dto from "../dto";

export class AuthController {
  constructor(
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private readonly authAdapter: AuthAdapter,
    private readonly cookieService: CookieServiceType
  ) {}

  public login(payload: dto.AuthorizationDto) {
    return new Promise((resolve) => {
      this.authAdapter.setLoading();
      this.authService
        .authorization(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            const isAdmin = response.data.user.role === "admin";

            if (!isAdmin) {
              this.notificationService.activeNotification({
                status: "error",
                message: "Доступ запрещен",
              });
              return "error";
            }

            if (response.data.tokens) {
              this.setToken(response.data.tokens);
            }

            this.authAdapter.setProfile(response.data.user);

            const name = response.data.user.username;

            if (name) {
              this.notificationService.activeNotification({
                status: "success",
                message: `Добро пожаловать ${name}`,
              });
            }

            return resolve("success");
          } else {
            throw response.message;
          }
        })
        .catch((error) => {
          let rusError = error;

          if (error === "Password or username are wrong") {
            rusError = "Неверный логин или пароль";
          } else if (String(error).includes("User with username")) {
            rusError = `Пользователь с таким логином  не найден`;
          } else if (
            String(error).includes(
              "Password length must be at least 8 characters long"
            )
          ) {
            rusError = "Длина пароля должна составлять не менее 8 символов";
          }

          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${rusError}`,
          });

          return resolve(rusError);
        })
        .finally(() => this.authAdapter.resetLoading());
    });
  }

  public logout() {
    return this.authAdapter.logout(), this.resetToken();
  }

  public refreshToken() {
    return this.authService
      .fethRefreshToken()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.setToken(response.data.tokens);
          return  response.data.tokens.accessToken;
        } else {
          throw response.message;
        }
      })
      .catch((error) => {
        console.log(error); 
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        });
        this.resetToken();
        if (typeof window !== "undefined") {
          window.location.href = "/";
        }
        throw error;
      });
  }

  public async getProfile() {
    this.authAdapter.setLoading();
    this.authService
      .getProfile()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.authAdapter.setProfile(response.data.user);

        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
      )
      .finally(() => this.authAdapter.resetLoading());
  }

  public editProfileAdmin(payload: dto.EditProfileAdminDto) {
    return new Promise((resolve) => {
      this.authAdapter.setLoading();
      this.authService
        .editProfile(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.authAdapter.setProfile(response.data.user);
            this.notificationService.activeNotification({
              status: "success",
              message: "Вы успешно обновили данные",
            });
            return resolve("success");
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.authAdapter.resetLoading());
    });
  }

  public editPassword(payload: dto.EditProfileAdminPasswordDto) {
    return new Promise((resolve) => {
      this.authAdapter.setLoading();
      this.authService
        .editPassword(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.authAdapter.setProfile(response.data.user);
            this.notificationService.activeNotification({
              status: "success",
              message: "Вы успешно обновили данные",
            });
            return resolve("success");
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.authAdapter.resetLoading());
    });
  }

  public editAvatar(payload: FormData) {
    return new Promise((resolve) => {
      this.authAdapter.setLoading();
      this.authService
        .editAvatar(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            const avatar = response.data.user.avatar;
            this.authAdapter.setProfileAvatar(avatar);
            this.notificationService.activeNotification({
              status: "success",
              message: "Вы успешно обновили аватар",
            });
            return resolve(avatar);
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.authAdapter.resetLoading());
    });
  }

  private setToken({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) {
    this.cookieService.set(accessToken, CONFIG_APP.ACCESS_TOKEN_COOKIE);
    this.cookieService.set(refreshToken, CONFIG_APP.REFRESH_TOKEN_COOKIE);
  }

  private resetToken() {
    this.cookieService.delete(CONFIG_APP.ACCESS_TOKEN_COOKIE);
    this.cookieService.delete(CONFIG_APP.REFRESH_TOKEN_COOKIE);
  }
}

export const authController = new AuthController(
  new AuthService(),
  new NotificationService(),
  new AuthAdapter(),
  CookieService
);
