import { createStore } from "@/shared/service/create-store";
import { PrivacyInitState } from "../schema/schema";

const initState: PrivacyInitState = {
  privacy: null,
  loadingSubmit: false,
};

export const privacyStore = createStore({ initState });

export type PrivacyStoreType = typeof privacyStore;
