import { FetchService } from "@/shared";
import { BannerRepository } from "../repository/repository";
import * as dto from "../dto";

export class BannerService {
  private readonly repository: BannerRepository;

  constructor() {
    this.repository = new BannerRepository(new FetchService());
  }

  public fetchBanners(payload: dto.BannersParamsDto) {
    return this.repository.fetchBanners(payload);
  }

  public fethBanner(id: string) {
    return this.repository.fethBanner(id);
  }

  public fetchCreateBanner(payload: dto.CreateBannerDto) {
    return this.repository.fetchCreateBanner(payload);
  }

  public fethEditBanners(payload: {
    id: string;
    payload: dto.CreateBannerDto;
  }) {
    return this.repository.fethEditBanners(payload);
  }

  public fethRemoveBanner(id: string) {
    return this.repository.fethRemoveBanner(id);
  }

  public fethUpdateImage(payload: { id: string, file: File }) {
    return this.repository.fethUpdateImage(payload);
  }
  
}
