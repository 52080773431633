import { createStore } from "@/shared/service/create-store";
import { RegionInitState } from "../schema/schema";

const initState: RegionInitState = {
  regionList: [],
  currentRegion: null,
  loadingSubmit: false,
  currentCount: 0,
};

export const regionStore = createStore({ initState });

export type RegionStoreType = typeof regionStore;
