import { ManagerService } from "../service/service";
import { ManagerAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";
import { userController } from "@/entities/users";

class ManagerController {
  constructor(
    private readonly managerService: ManagerService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: ManagerAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getManagers(params: dto.ManagersParamsDto) {
    this.loadingController.activeFetchLoading();
    this.managerService
      .fethManagers(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setManagerList(response.data.regions);
          if (response.data.totalCount) {
            this.storeAdapter.setTotalCount(response.data.totalCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getManager(id: string | undefined) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.loadingController.activeFetchLoading();
    this.managerService
      .fethManager(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentManager(response.data);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public createManager(payload: dto.CreateManagerDto) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.managerService
        .fetchCreateManagers(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Менеджер успешно добавлен",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public editManager(payload: { id: string; payload: FormData }) {
    return new Promise((resolve) => {
      this.sendErrorGetID(payload.id);
      if (!payload.id) return;

      this.storeAdapter.setLoadingSubmit();
      this.managerService
        .fethEditManager(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.setCurrentManager(response.data);
            this.notificationService.activeNotification({
              status: "success",
              message: "Менеджер успешно обновлен",
            });
            return resolve(response.status);
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public removeManager(id: string) {
    return new Promise((resolve) => {
      this.sendErrorGetID(id);
      if (!id) return;
      
      this.storeAdapter.setLoadingSubmit();
      this.managerService
      .fethRemoveManagers(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);
          
          this.notificationService.activeNotification({
            status: "success",
            message: "Менеджер успешно удален",
          });
        } else {
          throw response.message;
        }
        return resolve(response.status)
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
          )
          )
          .finally(() => this.storeAdapter.resetLoadingSumbmit());
        })
  }

  public addManagerUser(payload: dto.AddManagerPayloadDto) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.managerService
        .fethAddManagerUser(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Менеджер успешно добавлен",
            });
            userController.getCurrentUser(String(payload.user_id));
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public removeManagerUser(id: string) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.managerService
        .fethRemoveManagerUser(id)
        .then((response) => {
          if (response.status === "success" && response.data) {
            console.log(response)
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const managerController = new ManagerController(
  new ManagerService(),
  new NotificationService(),
  new ManagerAdapter(),
  new LoadingController()
);
