import { FetchService } from "@/shared";
import { CategoryRepository } from "../repository/repository";
import * as dto from "../dto";

export class CategoryService {
  private readonly repository: CategoryRepository;

  constructor() {
    this.repository = new CategoryRepository(new FetchService());
  }

  public fethCategoryes(payload: dto.CategoryesParamsDto) {
    return this.repository.fethCategoryes(payload);
  }

  public fethCategory(id: string) {
    return this.repository.fethCategory(id);
  }

  public fetchCreateCategory(payload: dto.CreateCategoryDto) {
    return this.repository.fetchCreateCategory(payload);
  }

  public fethEditCategory(payload: {
    id: string;
    payload: dto.CreateCategoryDto;
  }) {
    return this.repository.fethEditCategory(payload);
  }

  public fethRemoveCategory(id: string) {
    return this.repository.fethRemoveCategory(id);
  }

  public fethUpdateCategoryImage(payload: { id: string, file: File }) {
    return this.repository.fethUpdateCategoryImage(payload);
  }

  public fetchTree() {
    return this.repository.fetchTree();
  }

  public fetchParentCategory(id: string) {
    return this.repository.fetchParentCategory(id);
  }

}
