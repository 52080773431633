import { FetchService } from "@/shared";
import { userEndpoints, type UserEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class UserRepository {
  private readonly endpoints: UserEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = userEndpoints;
  }

  public getUsersList(payload: Record<string, string>) {
    const url = this.endpoints.getUsers;

    return this.fethService.get({ url, params: payload })
    .then((data) => dto.UsersResponseDto.parseAsync(data));
  }

  public fethEditUser({
    id,
    payload,
  }: {
    id: string;
    payload: dto.EditUserPayloadDto;
  }) {
    const url = this.endpoints.editUserInfo(id);

    return this.fethService
      .put({ url, payload })
      .then((response) => dto.UserEditResponseDto.parseAsync(response));
  }

  public fethEditPassword({
    id,
    payload,
  }: {
    id: string;
    payload: dto.EditUserPasswordPayloadDto;
  }) {
    const url = this.endpoints.editUserPassword(id);

    return this.fethService
      .put({ url, payload })
      .then((response) => dto.UserResponseDto.parseAsync(response));
  }

  public removeUser(id: string) {
    const url = this.endpoints.removeUser(id);

    return this.fethService
      .delete({ url })
      .then((response) => dto.UserRemoveResponseDto.parseAsync(response));
  }

  public fethRoles() {
    const url = this.endpoints.getRoles;

    return this.fethService
      .get({ url })
      .then((response) => dto.RolesResponseDto.parseAsync(response));
  }

  public fethCurrentUser(id: string) {
    const url = this.endpoints.getUser(id);

    return this.fethService
      .get({ url })
      .then((response) => dto.UserResponseDto.parseAsync(response));
  }

  public fethEditUserAvatar({
    id,
    payload,
  }: {
    id: string;
    payload: FormData;
  }) {
    const url = this.endpoints.editUserAvatar(id);

    return this.fethService
      .put({ url, payload })
      // .then((response) => dto.UserResponseDto.parseAsync(response));
  }

  public fethCreateUser(payload: dto.CreateUsersPayloadDto) {
    const url = this.endpoints.createUser;

    return this.fethService
      .post({ url, payload })
      .then((response) => dto.UserResponseDto.parseAsync(response));
  }
}
