import { FetchService } from "@/shared";
import { feedbackEndpoints, type FeedbackEndpointsType } from "./endpoints";
import * as dto from "../dto";


export class FeedbackRepository {
 private readonly endpoints: FeedbackEndpointsType;
 
  constructor(private readonly fethService: FetchService) {   
    this.endpoints = feedbackEndpoints;
  }

  public fethFeedbacks(params: { page: string; limit: string }) {
    const url = this.endpoints.getAll;

    return this.fethService.get({ url, params })
    .then((data) => dto.FeedbacksResponseDto.parseAsync(data));
  }
  
  public fethFeedback(id: string) {
    const url = this.endpoints.getInfo(id);
    
    return this.fethService.get({ url })
    .then((data) => dto.FeedbackResponseDto.parseAsync(data));
  }
  
  public fethEditFeedback({ id, isChecked }: { id: string; isChecked: boolean }) {
    const url = this.endpoints.edit(id);
    
    return this.fethService.put({ url, payload: { isChecked } })
    .then((data) => dto.FeedbackResponseDto.parseAsync(data));
  }
  
  public fethRemoveFeedback(id: string) {
    const url = this.endpoints.remove(id);
    
    return this.fethService.delete({ url })
    .then((data) => dto.FeedbackRemoveResponseDto.parseAsync(data));
  }

}
