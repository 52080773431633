import { PrivacyModel } from "../schema/schema";
import { privacyStore, type PrivacyStoreType } from "./store";

export class PrivacyAdapter {
  public store: PrivacyStoreType;
  constructor() {
    this.store = privacyStore;
  }

  public setPrivacy(privacy: PrivacyModel) {
    return this.store.setState({ privacy: privacy });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }
}
