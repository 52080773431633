import { z } from "zod";

export const linkRegex = /^(http|https|ftp):/;

export const BannersParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
});

export const CreateBannerSchema = z.object({
  title: z.string().min(1, { message: "Введите заголовок" }),
  regions: z.array(z.number()),
  img: z.string().optional(),
  orientation: z.string().min(1, { message: "Выберите направление" }),

  url: z
    .string()
    .refine((value) => linkRegex.test(value), {
      message: "Введите действительную ссылку",
    })
    .or(z.literal("")),
});

export type BannersParamsDto = z.infer<typeof BannersParamsSchema>;
export type CreateBannerDto = z.infer<typeof CreateBannerSchema>;
