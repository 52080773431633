import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { BonusSchema } from "../schema/schema";

const BonusesResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  items: z.array(BonusSchema),
});

export const BonusesResponseDto = createResponseSchema(BonusesResponseSchema);
export const BonusResponseDto = createResponseSchema(BonusSchema);

