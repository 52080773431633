import { RegionSchema } from "@/entities/region";
import { number, z } from "zod";

export const ItemSchema = z.object({
  id: z.number(),
  title: z.string(),
  img: z.array(z.string()).nullable().or(z.string()),
  category_id: z.number().nullable().optional(),
  in_stock: z.number(),
  price: z.number().nullable(),
  price_discount: z.number().nullable().optional(),
  price_discount_wholesaler: z.number().nullable().optional(),
  price_wholesaler: z.number().nullable().optional(),
  description: z.string().nullable().optional(),
  description_units: z.string().nullable(),
  isHit: z.boolean().or(z.number()).nullable(),
  date_create: z.string().nullable().optional(),
  date_update: z.string().nullable().optional(),
  date_delete: z.string().nullable().optional(),
  brand: z.string().nullable().optional(),
  description_brand: z.string().nullable().optional(),
  product_line: z.string().nullable().optional(),
  purpose: z.string().nullable().optional(),
  description_general: z.string().nullable().optional(),
  description_use: z.string().nullable().optional(),
  description_compos: z.string().nullable().optional(),
  description_additional: z.string().nullable().optional(),
  regions: z.array(RegionSchema).optional().nullable(),
  article: z.string().nullable().optional(),
  isDiscount: z.boolean().optional(),
  isNew: z.boolean().optional(),
  recomendation: z
    .array(
      z.object({
        id: z.number(),
        isFavourite: z.boolean(),
        img: z.string().or(z.array(z.unknown())),
        title: z.string(),
        price: z.number(),
        price_discount: z.number().nullable(),
      })
    )
    .optional(),
  variable_units: z.unknown(),
  count: number().optional(),
});

const ItemInitStateSchema = z.object({
  itemsList: z.array(ItemSchema),
  currentItem: ItemSchema.nullable(),
  loadingSubmit: z.boolean(),
  totalCount: z.number(),
  currentCount: z.number(),
});

export type ItemModel = z.infer<typeof ItemSchema>;
export type ItemInitState = z.infer<typeof ItemInitStateSchema>;
