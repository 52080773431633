import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { BannerSchema } from "../schema/schema";

const BannersResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  items: z.array(BannerSchema),
});

export const BannersResponseDto = createResponseSchema(BannersResponseSchema);
export const CurrentBannerResponseDto = createResponseSchema(BannerSchema);
