import { z } from "zod";

export const PhraseSchema = z.object({
  id: z.number(),
  phrase: z.string(),
  translatedPhrase: z.string(),
  languageId: z.number(),
});

const PhraseInitStateSchema = z.object({
  phraseList: z.array(PhraseSchema),
  currentPhrase: PhraseSchema.nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
  currentTranslate: PhraseSchema.nullable(),
  translatesList: z.array(PhraseSchema),
});

export type PhraseModel = z.infer<typeof PhraseSchema>;
export type PhraseInitState = z.infer<typeof PhraseInitStateSchema>;
