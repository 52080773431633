export type BonusEndpointsType = {
  getList: string;
  bonusUser: (id: string) => string;
  getOneBonus: (id: string) => string;
};

export const bonusEndpoints: BonusEndpointsType = {
  getList: "bonus",
  bonusUser: (id: string) => `bonus/user/${id}`,
  getOneBonus: (id: string) => `bonus/${id}`,
};
