import { OrderService } from "../service/service";
import { OrderAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

class OrderController {
  constructor(
    private readonly orderService: OrderService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: OrderAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getOrders(params: dto.OrdersParamsDto) {
    this.loadingController.activeFetchLoading();
    this.orderService
      .fetchOrders(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setOrdersList(response.data.orders);

          if (response.data.currentCount) {
            this.storeAdapter.setTotalCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getOrder(id: string | undefined) {
    return new Promise((resolve) => {
      this.sendErrorGetID(id);
      if (!id) return;

      this.loadingController.activeFetchLoading();
      this.orderService
        .fetchOrder(id)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.setCurrentOrder(response.data);
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.loadingController.resetFethLoading());
    });
  }

  public createOrder(payload: any) {
    return new Promise((resolve) => {
      this.loadingController.activeFetchLoading();
      this.orderService
        .fetchCreateOrder(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.loadingController.resetFethLoading());
    });
  }

  public changeStatusOrder(payload: { order_id: number; status: number }) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.orderService
        .fetchChangeStatusOrder(payload)
        .then((response) => {

          if (response.status === "success" && response.data) {
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public getStatisticsDashboard(date: string) {
    return new Promise((resolve) => {
      this.loadingController.activeFetchLoading();
      this.orderService
      .fetchStatisticsDashboard(date)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setOrderStatistics(response.data);
        } else {
          throw response.message;
        }
        return resolve(response.status);
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
          )
          )
          .finally(() => this.loadingController.resetFethLoading());
        })
        }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const orderController = new OrderController(
  new OrderService(),
  new NotificationService(),
  new OrderAdapter(),
  new LoadingController()
);
