import { FetchService } from "@/shared";
import { ReviewsRepository } from "../repository/repository";
import * as dto from "../dto";

export class ReviewsService {
  private readonly repository: ReviewsRepository;

  constructor() {
    this.repository = new ReviewsRepository(new FetchService());
  }

  public fethReviewsList(payload: dto.ReviewsParamsDto) {
    return this.repository.fethReviewsList(payload);
  }

  public fethReview(id: string) {
    return this.repository.fethReview(id);
  }

  public fetchCreateReviews(payload: dto.CreateReviewsDto) {
    return this.repository.fetchCreateReviews(payload);
  }

  public fethEditReviews(payload: {
    id: string;
    payload: dto.CreateReviewsDto;
  }) {
    return this.repository.fethEditReviews(payload);
  }

  public fethRemoveReviews(id: string) {
    return this.repository.fethRemoveReviews(id);
  }
}
