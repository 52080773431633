export type RegionsEndpointsType = {
  create: string;
  getList: string;
  getOne: (id: string) => string;
  remove: (id: string) => string;
  edit: (id: string) => string;
  editImage: (id: string) => string;
  addManager: string;
  removeManagerUser: (id: string) => string;
};

export const regionEndpoints = {
  create: "managers",
  getList: "managers",
  getOne: (id: string) => `managers/${id}`,
  remove: (id: string) => `managers/${id}`,
  edit: (id: string) => `managers/${id}`,
  editImage: (id: string) => `managers/image/${id}`,
  addManager: "managers/user",
  removeManagerUser: (id: string) => `managers/user/${id}`,
};
