import { ReviewsService } from "../service/service";
import { ReviewsAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

class ReviewsController {
  constructor(
    private readonly reviewsService: ReviewsService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: ReviewsAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getReviews(params: dto.ReviewsParamsDto) {
    this.loadingController.activeFetchLoading();
    this.reviewsService
      .fethReviewsList(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setReviewsList(response.data.feedback);

          if (response.data.currentCount) {
            this.storeAdapter.setTotalCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getReview(id: string | undefined) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.loadingController.activeFetchLoading();
    this.reviewsService
      .fethReview(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentReviews(response.data);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public createReview(payload: dto.CreateReviewsDto) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.reviewsService
        .fetchCreateReviews(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Отзыв успешно добавлен",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public editReviews(payload: { id: string; payload: dto.CreateReviewsDto }) {
    return new Promise((resolve) => {
      this.sendErrorGetID(payload.id);
      if (!payload.id) return;

      this.storeAdapter.setLoadingSubmit();
      this.reviewsService
        .fethEditReviews(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.setCurrentReviews(response.data);
            this.notificationService.activeNotification({
              status: "success",
              message: "Отзыв успешно обновлен",
            });
            return resolve(response.status);
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public removeReview(id: string) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.storeAdapter.setLoadingSubmit();
    this.reviewsService
      .fethRemoveReviews(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);

          this.notificationService.activeNotification({
            status: "success",
            message: "Отзыв успешно удален",
          });
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const reviewsController = new ReviewsController(
  new ReviewsService(),
  new NotificationService(),
  new ReviewsAdapter(),
  new LoadingController()
);
