import { createResponseSchema } from "@/shared";
import { EventSchema } from "../schema/schema";
import { z } from "zod";

const EventsResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  eventsStatistics: z.array(EventSchema),
  statisticsByDay: z.array(z.unknown()).optional(),
});

const EventResponseSchema = z.object({
  event: EventSchema,
});

const EventRemoveResponseSchema = z.object({
  message: z.string(),
});

export const EventsResponseDto = createResponseSchema(EventsResponseSchema);
export const EventResponseDto = createResponseSchema(EventResponseSchema);
export const EventRemoveResponseDto = createResponseSchema(EventRemoveResponseSchema);
