import { lazy } from "react";

export const AccountPage = lazy(() => import("./Account/Account"));
export const DashboardPage = lazy(() => import("./Dashboard/Dashboard"));
export const UserInfoPage = lazy(() => import("./User/user-info/UserInfo"));
export const UserEditPage = lazy(() => import("./User/user-edit/UserEdit"));
export const UserAddPage = lazy(() => import("./User/user-add/UserAdd"));
export const UserListPage = lazy(() => import("./User/UserList"));
export const FeedBackEditPage = lazy(() => import("./FeedBacks/FeedBackEdit"));
export const FeedBackListPage = lazy(() => import("./FeedBacks/FeedBackList"));
export const EmailTemplateListPage = lazy(
  () => import("./EmailTemplates/EmailTemplateList")
);
export const EmailHistoryListPage = lazy(
  () => import("./EmailHistory/EmailHistoryList")
);
export const EmailTemplateCreatePage = lazy(
  () => import("./EmailTemplates/EmailTemplateCreate")
);
export const EmailTemplateEditPage = lazy(
  () => import("./EmailTemplates/EmailTemplateEdit")
);
export const SmtpPage = lazy(
  () => import("./Smtp/SmtpPage")
);
export const AppLogsPage = lazy(() => import("./AppLogs/AppLogs"));
export const AppStatisticsEventsListPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsList")
);
export const AppStatisticsEventsAddPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsAdd")
);
export const AppStatisticsEventsEditPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsEdit")
);
export const LogsPage = lazy(() => import("./Logs/Logs"));
export const CommonSettingsPage = lazy(
  () => import("./CommonSettings/CommonSettings")
);
export const UpdateSystemPage = lazy(
  () => import("./UpdateSystem/UpdateSystem")
);
export const SettingsPage = lazy(() => import("./Settings/Settings"));

export const LanguageListPage = lazy(() => import("./Language/LanguageList"));
export const LanguageAddPage = lazy(() => import("./Language/LanguageAdd"));
export const LanguageEditPage = lazy(() => import("./Language/LanguageEdit"));

export const PhraseListPage = lazy(() => import("./Phrases/PhrasesList"));
export const PhraseAddPage = lazy(() => import("./Phrases/PhrasesAdd"));
export const PhraseEditPage = lazy(() => import("./Phrases/PhrasesEdit"));

export const ConfigurationKeysListPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysList")
);
export const ConfigurationKeysAddPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysAdd")
);
export const ConfigurationKeysEditPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysEdit")
);
export const LoginPage = lazy(() => import("./Login/Login"));

//---------------------Template-----------------------------

export const ItemListPage = lazy(() => import("./Item/ItemList"));
export const ItemAddPage = lazy(() => import("./Item/item-add/ItemAdd"));
export const ItemEditPage = lazy(() => import("./Item/item-edit/ItemEdit"));
export const ItemInfoPage = lazy(() => import("./Item/item-info/ItemInfo"));

export const RegionListPage = lazy(() => import("./Region/region-list/RegionList"));
export const RegionAddPage = lazy(() => import("./Region/region-add/RegionAdd"));
export const RegionEditPage = lazy(() => import("./Region/region-edit/RegionEdit"));

export const CategoryListPage = lazy(() => import("./Category/category-list/CategoryList"));
export const CategoryAddPage = lazy(() => import("./Category/category-add/CategoryAdd"));
export const CategoryEditPage = lazy(() => import("./Category/category-edit/CategoryEdit"));

export const ReviewsListPage = lazy(() => import("./Reviews/reviews-list/ReviewsList"));
export const ReviewsEditPage = lazy(() => import("./Reviews/reviews-edit/ReviewsEdit"));

export const PrivacyInfoPage = lazy(() => import("./privacy/privacy-info/Privacy"));
export const PrivacyEditPage = lazy(() => import("./privacy/privacy-edit/PrivacyEdit"));

export const BonusListPage = lazy(() => import("./Bonuses/bonuses-list/BonusesList"));
export const BonusInfoPage = lazy(() => import("./Bonuses/bonuses-info/BonusesInfo"));

export const BannersListPage = lazy(() => import("./Banners/banners-list/BannersList"));
export const BannersAddPage = lazy(() => import("./Banners/banners-add/BannersAdd"));
export const BannersEditPage = lazy(() => import("./Banners/banners-edit/BannersEdit"));
export const BannersInfoPage = lazy(() => import("./Banners/banners-info/BannersInfo"));

export const OrderListPage = lazy(() => import("./Order/order-list/OrderList"));
export const OrderInfoPage = lazy(() => import("./Order/order-info/OrderInfo"));

export const ManagersInfoPage = lazy(() => import("./Managers/managers-info/ManagersInfo"));
export const ManagersEditPage = lazy(() => import("./Managers/managers-edit/ManagersEdit"));
export const ManagersAddPage = lazy(() => import("./Managers/managers-add/ManagersAdd"));
export const ManagersListPage = lazy(() => import("./Managers/managers-list/ManagersList"));
export const PoliticSettingPage = lazy(() => import("./Politic/PoliticSetting"));

export const DocumentsListPage = lazy(() => import("./Documents/documents-list/DocumentsList"));
export const DocumentsAddPage = lazy(() => import("./Documents/documents-add/DocumentsAdd"));
export const DocumentsEditPage = lazy(() => import("./Documents/documents-edit/DocumentsEdit"));

export const SocialSettingsPage = lazy(() => import("./Social/SocialSettigs"));