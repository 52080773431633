import { createStore } from "@/shared/service/create-store";
import { UserInitState } from "../schema/schema";

const initState: UserInitState = {
  users: [],
  currentUser: null,
  currenUsertId: null,
  currentCount: 0,
  loading: false,
  roles: [],
  loadingSubmit: false,
};

export const userStore = createStore({ initState });

export type userStoreType = typeof userStore;