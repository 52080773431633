import { FetchService } from "@/shared";
import { systemEndpoints, type SystemEndpointsType } from "./endpoints";
import { BackendImportFileResponseSchemaDto, BackendZipNameResponseDto } from "../dto";


export class SystemRepository {
  private readonly endpoints: SystemEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = systemEndpoints;
  }

  public fethBackendExportZipName() {
    const url = this.endpoints.getBackendZipName;

    return this.fethService.get({ url })
    .then(data => BackendZipNameResponseDto.parseAsync(data));
  }

  public fethBackendImport(file: File) {
    const url = this.endpoints.backendImport;

    const payload = new FormData();
    payload.append("backend", file);

    return this.fethService.put({ url, payload })
    .then(data => BackendImportFileResponseSchemaDto.parseAsync(data));
  }
}
