import { FetchService } from "@/shared";
import { itemEndpoints, type ItemEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class ItemRepository {
  private readonly endpoints: ItemEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = itemEndpoints;
  }

  public fethItems(params: Record<string, string>) {
    const url = this.endpoints.getList;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.ItemsResponseDto.parseAsync(data));
  }

  public fethItem(id: string) {
    const url = this.endpoints.getOne(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.ItemResponseDto.parseAsync(data));
  }

  public fetchCreateItem(payload: dto.CreateItemPayload) {
    const url = this.endpoints.create;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.CreateItemResponseSchemaDto.parseAsync(data));
  }

  public fetchEditImageItem({
    id,
    payload,
  }: {
    id: string;
    payload: FormData;
  }) {
    const url = this.endpoints.editImage(id);

    return this.fethService.put({ url, payload });
    // .then((data) => dto.EdigImageItemResponseSchemaDto.parseAsync(data));
  }

  public fetchRemoveImageItem({ id, image }: { id: string; image: string }) {
    const url = this.endpoints.removeImage(id);

    return this.fethService.post({ url, payload: { image } });
    // .then((data) => dto.CreateItemResponseSchemaDto.parseAsync(data));
  }

  public fethEditItem({
    id,
    payload,
  }: {
    id: string;
    payload: dto.CreateItemPayload;
  }) {
    const url = this.endpoints.edit(id);

    return this.fethService
      .put({ url, payload })
      .then((data) => dto.CreateItemResponseSchemaDto.parseAsync(data));
  }

  public fethRemoveItem(id: string) {
    const url = this.endpoints.remove(id);

    return this.fethService
      .delete({ url })
      .then((data) => dto.RemoveItemResponseDto.parseAsync(data));
  }

  public createRecomendation(payload: { item_id: number, recomendation_id: number}) {
    const url = this.endpoints.createRecomendation;

    return this.fethService.post({ url, payload });
    // .then((data) => dto.CreateItemRecomendResponseDto.parseAsync(data));
  }

  public deleteRecomendation(id: string) {
    const url = this.endpoints.removeRecomendation(id);

    return this.fethService.delete({ url });
    // .then((data) => dto.RemoveItemResponseDto.parseAsync(data));
  }
}
