import { createStore } from "@/shared/service/create-store";
import { EmailInitState } from "../schema/schema";

const initState: EmailInitState = {
  emailList: [],
  currentEmail: null,
  loadingSubmit: false,
  totalCount: 0,
};

export const emailStore = createStore({ initState });

export type emailStoreType = typeof emailStore;