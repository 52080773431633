import { FetchService } from "@/shared";
import { RegionsRepository } from "../repository/repository";
import * as dto from "../dto";

export class RegionService {
  private readonly repository: RegionsRepository;

  constructor() {
    this.repository = new RegionsRepository(new FetchService());
  }

  public fethRegions(payload: dto.RegionsParamsDto) {
    return this.repository.fethRegions(payload);
  }

  public fethRegion(id: string) {
    return this.repository.fethRegion(id);
  }

  public fetchCreateRegion(payload: dto.CreateRegionDto) {
    return this.repository.fetchCreateRegion(payload);
  }

  public fethEditRegion(payload: { id: string; payload: dto.CreateRegionDto }) {
    return this.repository.fethEditRegion(payload);
  }

  public fethRemoveRegion(id: string) {
    return this.repository.fethRemoveRegion(id);
  }
}
