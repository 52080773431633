import { LanguageService } from "../service/service";
import { ConfigKeysAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";


class LanguageController {
  constructor(
    private readonly languageService: LanguageService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: ConfigKeysAdapter,
    private readonly loadingController: LoadingController,
  ) {}


  public getLanguages(params: dto.GetLanguagesParamsDto) {
    this.loadingController.activeFetchLoading();
    this.languageService
      .fethLanguages(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setKeysList(response.data.languages);

          if (response.data.currentCount) {
            this.storeAdapter.setTotalCount(response.data.currentCount);
          }
          
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getLanguage(id: string | undefined) {
  this.sendErrorGetID(id);
  if (!id)return;

    this.loadingController.activeFetchLoading();
    this.languageService
      .fethLanguage(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentKey(response.data.language);
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public createLanguge(payload: dto.CreateLanguagePayload) {
    return new Promise((resolve) => {
    this.storeAdapter.setLoadingSubmit();
    this.languageService
      .fetchCreateLanguage(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.notificationService.activeNotification({
            status: "success",
            message: "Язык успешно добавлен",
          });
        } else {
          throw response.message;
        }
        return resolve(response.status);
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
    })
  }

  public editLanguage(payload: { id: string, payload: dto.EditLanguageDto }) {
    return new Promise(resolve => {
      this.sendErrorGetID(payload.id);
      if (!payload.id) return;
      
      this.storeAdapter.setLoadingSubmit();
      this.languageService
      .fethEditLanguage(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentKey(response.data.language);
          this.notificationService.activeNotification({
            status: "success",
            message: "Язык успешно обновлен",
          });
          return resolve(response.status);
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
      })
      }

  public removeLanguage(id: string) {
      this.sendErrorGetID(id);
      if (!id)return;
      
      this.storeAdapter.setLoadingSubmit();
      this.languageService
      .fethRemoveLanguage(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);

          this.notificationService.activeNotification({
            status: "success",
            message: "Язык успешно удален",
          });
          
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
    this.notificationService.activeNotification({
        status: "error",
        message: 'Ошибка: ID не найден',
      })
    }
  }


}

export const languageController = new LanguageController(
  new LanguageService(),
  new NotificationService(),
  new ConfigKeysAdapter(),
  new LoadingController(),
);
