export const METODATA_TITLES: Record<string, string> = {
  "/dashboard": "Дашборд",
  "/users": "Пользователи",
  "/users/add": "Создать нового пользователя",
  "/feedbacks": "Обратная связь",
  "/email-templates": "Шаблоны электронной почты",
  "/email/smtp": "Настройка электронной почты",
  "/email-templates/create": "Создание шаблона электронной почты",
  "/email-history": "История рассылки",
  "/settings": "Адрес",
  "/configuration/keys": "Конфигуратор ключей",
  "/configuration/keys/add": "Создайте новый конфигурационный ключ",
  "/app-logs": "Логи приложения",
  "/logs": "Все логи",
  "/app-statistics": "Статистика заявок",
  "/events": "События",
  "/events/add": "Добавить событие",
  "/common-settings": "Общие настройки",
  "/update-system": "Обновление системы",
  "/login": "Авторизация",
  "/account": "Профиль",
  "/language/list": "Языки",
  "/language/add": "Добавить язык",
  //----template--------
  "/items": "Список товаров",
  "/items/add": "Добавить товар",
  "/regions": "Список регионов",
  "/regions/add": "Добавить регионов",
  "/category": "Список категорий",
  "/reviews": "Отзывы",
  "/privacy": "Пользовательское соглашение",
  "/bonuses": "Бонусы",
  "/banners": "Баннеры",
  "/banners/add": "Добавить баннер",
  "/orders": "Заказы",
  "/managers": "Менеджеры",
  "/managers/add": "Добавить менеджера",
  "/policy-confidentiality": "Политика конфиденциальности",
  "/documents": "Документы",
  "/documents/add": "Добавить документ",
  "/social-settings": "Социальные сети",
};

export const METODATA_TITLES_DINAMIC: Record<string, string> = {
  "/users/info/": "Информация пользователя",
  "/users/edit/": "Редактировать пользователя",
  "/feedbacks/edit/": "Редактировать отзыв",
  "/email-templates/edit/": "Обновление шаблона электронной почты",
  "/events/edit/": "Редактировать событие",
  "/configuration/keys/edit/": "Редактировать",
  "/language/edit/": "Редактировать язык",
  "/phrases/edit/": "Редактировать перевод",
  "/phrases/add/": "Добавить перевод",
  "/phrases/": "Переводы фраз",
  //----template--------
  "/items/edit/": "Редактировать товар",
  "/items/info/": "Информация о товаре",
  "/regions/edit/": "Редактировать регион",
  "/category/edit/": "Редактировать категорию",
  "/reviews/edit/": "Редактировать отзыв",
  "/privacy/edit": "Редактировать пользовательское соглашение",
  "/bonuses/info/": "Бонус",
  "/banners/edit/": "Редактировать баннер",
  "/banners/info/": "Информация о баннере",
  "/orders/info": "Информация заказа",
  "/managers/info/": "Информация менеджера",
  "/managers/edit/": "Редактировать менеджера",
  "/category/add/": "Добавить категорию",
  "/documents/edit/": "Редактировать документ",
};
