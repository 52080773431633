import { z } from "zod";

export const ManagerSchema = z.object({
  id: z.number(),
  fio: z.string(),
  phone: z.string(),
  img: z.string().nullable(),
  date_create: z.string(),
});

const ManagerInitStateSchema = z.object({
  managerList: z.array(ManagerSchema),
  currentManager: ManagerSchema.nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
});

export type ManagerModel = z.infer<typeof ManagerSchema>;
export type ManagerInitState = z.infer<typeof ManagerInitStateSchema>;
