import { ItemSchema } from '@/entities/item';
import { z } from 'zod';

export const AddressDeliverySchema = z.object({
  house_street: z.string(),
  flat: z.string().optional(),
  floor: z.string().optional(),
  doorphone: z.string().optional(),
  entrance: z.string().optional(),
});

export const RecipientSchema = z.object({
  name: z.string(),
  surname: z.string(),
  patronymic: z.string().optional(),
  phone: z.string(),
});

export const OrderSchema = z.object({
  id: z.number(),
  user_id: z.number().nullable(),
  priceData: z.array(ItemSchema),
  address_delivery: AddressDeliverySchema,
  recipient: RecipientSchema,
  status: z.string().nullable(),
  price_discount: z.number(),
  price_delivery: z.number(),
  used_bonus: z.number().nullable(),
  price_result: z.number(),
  price: z.number(),
  date_create: z.string().nullable(),
  date_update: z.string().nullable(),
  comment: z.string().nullable(),
});

export const OrderStatisticSchema = z.object({
  date: z.string(),
  count: z.number(),
});

const OrderInitStateSchema = z.object({
  ordersList: z.array(OrderSchema),
  currentOrder: OrderSchema.nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
  statistics: z.array(OrderStatisticSchema),
});

export type OrderModel = z.infer<typeof OrderSchema>;
export type OrderInitState = z.infer<typeof OrderInitStateSchema>;
export type OrderStatisticModel = z.infer<typeof OrderStatisticSchema>;
