export type PhraseEndpointsType = {
  create: string;
  getList: string;
  getOne: (id: string) => string;
  remove: (id: string) => string;
  edit: (id: string) => string;
  translate: string;
  translates: string;
};

export const phraseEndpoints = {
  create: "translations",
  getList: "translations",
  getOne: (id: string) => `translations/${id}`,
  remove: (id: string) => `translations/${id}`,
  edit: (id: string) => `translations/${id}`,
  translate: "translations/phrase",
  translates: "translations/phrases",
};
