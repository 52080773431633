import { z } from "zod";

export const GetTemplatesParamsSchema = z.object({
  key: z.string().optional(),
  id: z.string().optional(),
  subject: z.string().optional(),
  page: z.string().optional(),
  limit: z.string().optional(),
});

export const CreateTemplatePayloadSchema = z.object({
  subject: z.string().min(1, { message: 'Введите Шаблон' }),
  key: z.string().min(1, { message: 'Введите Ключ' }),
  comment: z.string().min(1, { message: 'Введите Комментарий' }),
  draft: z.string().optional(),
  html: z.string().optional(),
});


export type GetTemplatesParamsDto = z.infer<typeof GetTemplatesParamsSchema>;
export type CreateTemplatePayloadDto = z.infer<typeof CreateTemplatePayloadSchema>;
