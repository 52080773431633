import { FetchService } from "@/shared";
import { StatisticsRepository } from "../repository/repository";
import * as dto from "../dto";


export class StatisticsService {
  private readonly repository: StatisticsRepository;

  constructor() {
    this.repository = new StatisticsRepository(new FetchService());
  }

  public fethStatisticsEvents(payload: dto.EventsParamsDto) {
    return this.repository.fethStatisticsEvents(payload);
  }

  public fethStatisticsEvent(id: string) {
    return this.repository.fethStatisticsEvent(id);
  }

  public fethRemoveEvents(id: string) {
    return this.repository.fethRemoveStatisticsEvent(id);
  }

  public fethCreateEvents(payload: dto.CreateEventDto) {
    return this.repository.fethCreateStatisticsEvent(payload);
  }

  public fethEditEvents(id: string, payload: dto.CreateEventDto) {
    return this.repository.fethEditStatisticsEvent(id, payload);
  }
}
