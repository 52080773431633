import { FetchService } from "@/shared";
import { EmailRepository } from "../repository/repository";
import * as dto from "../dto";

export class EmailService {
  private readonly repository: EmailRepository;

  constructor() {
    this.repository = new EmailRepository(new FetchService());
  }

  public fetchEmailHistorys(payload: dto.GetEmailsParamsDto) {
    return this.repository.fetchEmailHistorys(payload);
  }

  public fetchRemoveEmails() {
    return this.repository.fetchRemoveEmails();
  }

  public fetchSendUserEmail(payload: dto.SendEmailDto) {
    return this.repository.fetchSendUserEmail(payload);
  }

  public fetchSendAllUserEmail(payload: dto.SendEmailDto) {
    return this.repository.fetchSendAllUserEmail(payload);
  }
}
