import { StatisticsAdapter } from "../store/adapter";
import { StatisticsService } from "../service/service";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";


export class StatisticsController {
  constructor(
    private readonly statisticsService: StatisticsService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: StatisticsAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public setCurrentId(id: number) {
    return this.storeAdapter.setCurrentId(id);
  }

  public resetCurrentId() {
    return this.storeAdapter.resetCurrentId();
  }

  public getEventStatistics(params: dto.EventsParamsDto) {
    this.loadingController.activeFetchLoading();
    this.statisticsService
      .fethStatisticsEvents(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setStatistics(response.data.eventsStatistics);

          if (response.data.totalCount) {
            this.storeAdapter.setTotalCount(response.data.totalCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getStatisticEvent(id: string | undefined) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.loadingController.activeFetchLoading();
    this.statisticsService
      .fethStatisticsEvent(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentStatistic(response.data.event);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public createStatisticsEvent(payload: dto.CreateEventDto): Promise<string> {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.statisticsService
        .fethCreateEvents(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Событие успешно добавлено",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status ? response.status : "error");
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public editStatisticsEvent(
    id: string | undefined,
    payload: dto.CreateEventDto
  ) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.storeAdapter.setLoadingSubmit();
    this.statisticsService
      .fethEditEvents(id, payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentStatistic(response.data.event);
          this.notificationService.activeNotification({
            status: "success",
            message: "Событие успешно обновлено",
          });
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public removeStatistic(id: string) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.storeAdapter.setLoadingSubmit();
    this.statisticsService
      .fethRemoveEvents(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);

          this.notificationService.activeNotification({
            status: "success",
            message: "Событие успешно удалено",
          });
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const statisticsController = new StatisticsController(
  new StatisticsService(),
  new NotificationService(),
  new StatisticsAdapter(),
  new LoadingController()
);
