import { UserModel } from "../schema/schema";
import { userStore, userStoreType } from "./store";


export class UserAdapter {
  public store: userStoreType
  constructor() {
    this.store = userStore;
  }

  public setUsers(users: UserModel[]) {
    return this.store.setState({ users });
  }

  public setCurrentUser(currentUser: UserModel) {
    return this.store.setState({ currentUser });
  }

  public setRoles( roles: string[] ) {
    return this.store.setState({ roles });
  }

  public filterUsers(id: number) {
    const userList = this.store.getState().users;

    return this.store.setState({ users: userList.filter(item => item.id !== id) });
  }

  public setCurrentCount(count: number) {
    return this.store.setState({ currentCount: count });
  }

  public setLoading() {
    return this.store.setState({ loading: true });
  }

  public resetLoading() {
    return this.store.setState({ loading: false });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSubmit() {
    return this.store.setState({ loadingSubmit: false });
  }

  public setCurrentId(id: number) {
    return this.store.setState({ currenUsertId: id });
  }

  public resetCurrentId() {
    return this.store.setState({ currenUsertId: null });
  }

}


