import { FeedbackService } from "../service/service";
import { FeedbackAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";

export class FeedbackController {
  constructor(
    private readonly feedbackService: FeedbackService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: FeedbackAdapter,
    private readonly loadingController: LoadingController,
  ) {}

  public setCurrentId(id: number) {
    return this.storeAdapter.setCurrentId(id);
  }

  public resetCurrentId() {
    return this.storeAdapter.resetCurrentId();
  }

  public getFeedbacks(params: { page: string; limit: string }) {
    this.loadingController.activeFetchLoading();
    this.feedbackService
      .fetchFeedbacks(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setFeedbacks(response.data.feedbacks);

          if (response.data.totalCount) {
            this.storeAdapter.setTotalCount(response.data.totalCount);
          }
          
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        }))
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getFeedback(id: string | undefined) {
  this.sendErrorGetID(id);
  if (!id)return;

    this.loadingController.activeFetchLoading();
    this.feedbackService
      .fetchFeedback(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentFeedback(response.data.feedback);
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        }))
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public editFeedback(id: string | undefined, isChecked: boolean) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.storeAdapter.setLoadingSubmit();
    this.feedbackService
      .fetchEditFeedback({ id, isChecked })
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentFeedback(response.data.feedback);
          this.notificationService.activeNotification({
            status: "success",
            message: "Статус заявки успешно обновлен",
          });
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        }))
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public removeFeedback(id: string) {
    this.sendErrorGetID(id);
    if (!id)return;

    this.storeAdapter.setLoadingSubmit();
    this.feedbackService
      .fetchRemoveFeedback(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);

          this.notificationService.activeNotification({
            status: "success",
            message: "Отзыв успешно удален",
          });

        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        }))
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
    this.notificationService.activeNotification({
        status: "error",
        message: 'Ошибка: ID не найден',
      })
    }
  }


}

export const feedbackController = new FeedbackController(
  new FeedbackService(),
  new NotificationService(),
  new FeedbackAdapter(),
  new LoadingController(),
);
