import { FetchService } from "@/shared";
import { regionEndpoints, type RegionsEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class ManagerRepository {
  private readonly endpoints: RegionsEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = regionEndpoints;
  }

  public fethManagers(params: dto.ManagersParamsDto) {
    const url = this.endpoints.getList;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.ManagersResponseDto.parseAsync(data));
  }

  public fethManager(id: string) {
    const url = this.endpoints.getOne(id);

    return this.fethService.get({ url })
    .then(data => dto.CurrentManagerResponseDto.parseAsync(data));
  }

  public fetchCreateManagers(payload: dto.CreateManagerDto) {
    const url = this.endpoints.create;

    return this.fethService.post({ url, payload })
    .then(data => dto.CurrentManagerResponseDto.parseAsync(data));
  }

  public fethEditManager({ id, payload }: { id: string; payload: FormData }) {
    const url = this.endpoints.editImage(id);

    return this.fethService.put({ url, payload })
    .then(data => dto.CurrentManagerResponseDto.parseAsync(data));
  }

  public fethRemoveManagers(id: string) {
    const url = this.endpoints.remove(id);

    return this.fethService.delete({ url });
  }

  public fethAddManagerUser(payload: dto.AddManagerPayloadDto) {
    const url = this.endpoints.addManager;

    return this.fethService.post({ url, payload });
  }

  public fethRemoveManagerUser(id: string) {
    const url = this.endpoints.removeManagerUser(id);

    return this.fethService.delete({ url });
  }
}
