import { Link, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import MenuSvg from "../../../../public/assets/svg/menu-svgrepo-com.svg";
import InputSvg from "../../../../public/assets/svg/input-svgrepo-com.svg";
import LogoSvg from "../../../../public/assets/svg/logo.svg";
import { layoutController, layoutStore } from "@/shared";
import { authController } from "@/entities";

import logoPng from "../../../../public/assets/images/logo-gk.png";

import styles from "./LayoutHeader.module.scss";

type Props = {
  isLoginPage: boolean;
};

const LayoutHeader = ({ isLoginPage }: Props) => {
  const isOpenMenu = layoutStore((state) => state.sideBarMenuState);
  const navigate = useNavigate();

  const handleToggleMenu = () => {
    if (isOpenMenu) {
      return layoutController.hideSideMenu();
    }
    return layoutController.activeSideMenu();
  };

  const handleLogout = () => {
    authController.logout();
    navigate("/");
  };

  return (
    <header className={styles.header}>
      <section className={styles.logoContainer}>
        <img 
        src={logoPng}
         alt="Logo Gkmaximum" 
         className={styles.logo}
         />
         <h1 className={styles.logoName}>GkMaximum Админпанель</h1>
      </section>
      <div>
        {!isLoginPage && (
          <>
            <IconButton color="inherit" size="large" onClick={handleLogout}>
              <InputSvg width={26} height={26} color="white" />
            </IconButton>
            <IconButton
              className={styles.buttonMenu}
              color="inherit"
              onClick={handleToggleMenu}
              size="large"
            >
              <MenuSvg width={20} height={20} color="white" />
            </IconButton>
          </>
        )}
      </div>
    </header>
  );
};

export { LayoutHeader };
