export type AuthEndpointsType = {
  login: string;
  refresh: string;
  getProfile: string;
  changeAvatar: string;
  editProfile: string;
  changePassword: string;
  recoveryPassword: string;
};

export const authEndpoints: AuthEndpointsType = {
  login: "login/admin",
  refresh: "refresh",
  getProfile: "users/current",
  changeAvatar: "users/current/avatar",
  editProfile: "users/current",
  recoveryPassword: "change/password",
  changePassword: "admin/current/password",
};
