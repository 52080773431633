import { createStore } from "@/shared/service/create-store";
import { CategoryInitState } from "../schema/schema";


const initState: CategoryInitState = {
  categoryList: [],
  parentCategory: [],
  subParrentCategorys: [],
  currentCategory: null,
  loadingSubmit: false,
  currentCount: 0,
  parentLoading: false,
  subParentLoading: false,
};

export const categoryStore = createStore({ initState });

export type CategoryStoreType = typeof categoryStore;