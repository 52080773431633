import { FetchService } from "@/shared";
import { OrderRepository } from "../repository/repository";
import * as dto from "../dto";

export class OrderService {
  private readonly repository: OrderRepository;

  constructor() {
    this.repository = new OrderRepository(new FetchService());
  }

  public fetchOrders(payload: dto.OrdersParamsDto) {
    return this.repository.fetchOrders(payload);
  }

  public fetchOrder(id: string) {
    return this.repository.fetchOrder(id);
  }

  public fetchCreateOrder(payload: any) {
    return this.repository.fetchCreateOrder(payload);
  }

  public fetchChangeStatusOrder(payload: { order_id: number; status: number }) {
    return this.repository.fetchChangeStatusOrder(payload);
  }

  public fetchStatisticsDashboard(date: string) {
    return this.repository.fetchStatisticsDashboard(date);
  }

}
