import { createStore } from "@/shared/service/create-store";
import { FeedbackInitState } from "../schema/schema";

const initState: FeedbackInitState = {
  feedbacksList: [],
  currentFeedback: null,
  currentId: null,
  currentCount: 0,
  loadingSubmit: false,
  totalCount: 0,
};


export const feedbackStore = createStore({ initState });

export type feedbackStoreType = typeof feedbackStore;
