import { Outlet, useLocation } from "react-router-dom";
import { LayoutHeader } from "../layout-header/LayoutHeader";
import { LayoutAside } from "../layout-aside/LayoutAside";
import { ContentHeaderTitle } from "../content-header-title/ContentHeaderTitle";
import { NotificationList } from "@/widgets/notification";
import { LoadingOverlay } from "@/widgets/loading/LoadingOverlay";

import styles from "./MainLayout.module.scss";

const MainLayout = () => {
  const { pathname } = useLocation();

  const isLoginPage = pathname === "/";

  return (
    <main className={styles.root}>
      <LoadingOverlay />
      <LayoutHeader isLoginPage={isLoginPage} />
      {!isLoginPage && <LayoutAside />}
      <div
        className={
          isLoginPage
            ? `${styles.contentWrapper} ${styles.contentNoPadding}`
            : styles.contentWrapper
        }
      >
        <section className={styles.content}>
          <NotificationList />
          <ContentHeaderTitle isLoginPage={isLoginPage} pathname={pathname} />
          <Outlet />
        </section>
      </div>
    </main>
  );
};

export { MainLayout };
