import { createStore } from "@/shared/service/create-store";
import { LogsInitState } from "../schema/schema";

const initState: LogsInitState = {
  frontendLogsList: [],
  LogsList: [],
  loadingSubmit: false,
  totalLogs: 0,
  totalFrontendLogs: 0,
};

export const logsStore = createStore({ initState });

export type logsStoreType = typeof logsStore;