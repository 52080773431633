import { SystemService } from "../service/service";
import { SystemAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";

class SystemController {
  constructor(
    private readonly systemService: SystemService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: SystemAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getBackendZipName() {
    this.loadingController.activeFetchLoading();
    this.systemService
      .fethBackendExportZipName()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setZipName(response.data.backend);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public downloadBackendFile(file: File | null): Promise<string> {
    return new Promise((resolve) => {
      if (!file || !(file instanceof File)) {
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: Файл не найден`,
        });
        return resolve("error");
      }

      this.storeAdapter.setLoadingSubmit();
      this.systemService
        .fethBackendImport(file)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Вы успешно обновили файл",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }
}

export const systemController = new SystemController(
  new SystemService(),
  new NotificationService(),
  new SystemAdapter(),
  new LoadingController()
);
