import { FetchService } from "@/shared";
import { ItemRepository } from "../repository/repository";
import * as dto from "../dto";


export class ItemService {
  private readonly repository: ItemRepository;

  constructor() {
    this.repository = new ItemRepository(new FetchService());
  }

  public fethItems(payload: Record<string, string>) {
    return this.repository.fethItems(payload);
  }

  public fethItem(id: string) {
    return this.repository.fethItem(id);
  }

  public fetchCreateItem(payload: dto.CreateItemPayload) {
    return this.repository.fetchCreateItem(payload);
  }

  public fetchEditImageItem(payload: { id: string, payload: FormData }) {
    return this.repository.fetchEditImageItem(payload);
  }

  public fetchRemoveImageItem(payload: { id: string; image: string }) {
    return this.repository.fetchRemoveImageItem(payload);
  }

  public fethEditItem(payload: { id: string, payload: dto.CreateItemPayload }) {
    return this.repository.fethEditItem(payload);
  }

  public fethRemoveItem(id: string) {
    return this.repository.fethRemoveItem(id);
  }

  public createRecomendation(payload: { item_id: number, recomendation_id: number}) {
    return this.repository.createRecomendation(payload);
  }

  public deleteRecomendation(id: string) {
    return this.repository.deleteRecomendation(id);
  }
}
