import { z } from "zod";

export const ManagersParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
});

export const CreateManagerSchema = z.object({
  fio: z.string().min(1, { message: "Введите ФИО" }),
  file: z.string(),
  phone: z
  .string()
  .min(10, { message: "Введите не менее 10 символов" })
  .max(15, { message: "Введите  менее 15 символов" }),
});

export const AddManagerPayloadSchema = z.object({
  user_id: z.number(),
  manager_id: z.number(),
});

export type ManagersParamsDto = z.infer<typeof ManagersParamsSchema>;
export type CreateManagerDto = z.infer<typeof CreateManagerSchema>;
export type AddManagerPayloadDto = z.infer<typeof AddManagerPayloadSchema>;
