import { FetchService } from "@/shared";
import { PrivacyRepository } from "../repository/repository";
import * as dto from "../dto";

export class PrivacyService {
  private readonly repository: PrivacyRepository;

  constructor() {
    this.repository = new PrivacyRepository(new FetchService());
  }

  public fethPrivacy() {
    return this.repository.fethPrivacy();
  }

  public fethEditPrivacy(payload : dto.EditPrivacyDto) {
    return this.repository.fethEditPrivacy(payload);
  }

}
