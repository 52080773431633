import { BannerModel } from "../schema/schema";
import { bannerStore, type BannerStoreType } from "./store";


export class BannerAdapter {
  public store: BannerStoreType
  constructor() {
    this.store = bannerStore;
  }

  public setBannersList(banners: BannerModel[]) {
    return this.store.setState({ bannersList: banners });
  }

  public setCurrentBanner(banner: BannerModel) {
    return this.store.setState({ currentBanner: banner });
  }

  public filterById(id: string) {
    const prevList = this.store.getState().bannersList;

    return this.store.setState({ bannersList: prevList.filter(item => item.id !== Number(id)) });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ currentCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

}


