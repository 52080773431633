import { createResponseSchema } from "@/shared";
import { FrontendLogSchema, LogSchema } from "../schema/schema";
import { z } from "zod";

const FrontendLogsResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  logs: z.array(FrontendLogSchema),
});

const LogsResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  logs: z.array(LogSchema),
});

const CreateFrontendLogResponseSchema = z.object({
  log: FrontendLogSchema,
});

const LogsRemoveResponseSchema = z.object({
  message: z.string(),
});


export const FrontendLogsResponseDto = createResponseSchema(FrontendLogsResponseSchema);
export const LogsResponseDto = createResponseSchema(LogsResponseSchema);
export const CreateFrontendLogResponseDto = createResponseSchema(CreateFrontendLogResponseSchema);
export const LogsRemoveResponseDto = createResponseSchema(LogsRemoveResponseSchema);
