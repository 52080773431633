import { ItemService } from "../service/service";
import { ItemAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

class ItemController {
  constructor(
    private readonly itemService: ItemService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: ItemAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getItems(params: Record<string, string>) {
    this.itemService
      .fethItems(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setItemsList(response.data.items);

          if (response.data.totalCount) {
            this.storeAdapter.setTotalCount(response.data.totalCount);
          }
          if (response.data.currentCount) {
            this.storeAdapter.setCurrenCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      );
  }

  public getItem(id: string | undefined) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.itemService
      .fethItem(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentItem(response.data);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      );
  }

  public createItem(payload: dto.CreateItemPayload) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.itemService
        .fetchCreateItem(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Товар успешно добавлен",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public editImageItem(payload: {
    id: string;
    payload: FormData;
  }): Promise<any> {
    return this.itemService.fetchEditImageItem(payload);
  }

  public removeImageItem(payload: { id: string; image: string }): Promise<any> {
    return this.itemService.fetchRemoveImageItem(payload);
  }

  public editItem(payload: { id: string; payload: dto.CreateItemPayload }) {
    return new Promise((resolve) => {
      this.sendErrorGetID(payload.id);
      if (!payload.id) return;

      this.storeAdapter.setLoadingSubmit();
      this.itemService
        .fethEditItem(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.setCurrentItem(response.data);
            this.notificationService.activeNotification({
              status: "success",
              message: "Товар успешно обновлен",
            });
            return resolve(response.status);
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public removeItem(id: string) {
    return new Promise((resolve) => {
      this.sendErrorGetID(id);
      if (!id) return;

      this.storeAdapter.setLoadingSubmit();
      this.itemService
        .fethRemoveItem(id)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.filterById(id);

            this.notificationService.activeNotification({
              status: "success",
              message: "Товар успешно удален",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public createRecomendationItem(payload: {
    item_id: number;
    recomendation_id: number;
  }) {
    this.storeAdapter.setLoadingSubmit();
    this.itemService
      .createRecomendation(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.notificationService.activeNotification({
            status: "success",
            message: "Товар успешно добавлен в рекомендуемое",
          });
          this.getItem(String(payload.item_id));
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public removeRecomendationItem(id: string) {
    this.storeAdapter.setLoadingSubmit();
    this.itemService
      .deleteRecomendation(id)
      .then((response) => {
        if (response.status === "success") {
          this.storeAdapter.filterRecomendById(id);

          this.notificationService.activeNotification({
            status: "success",
            message: "Товар успешно удален из рекомендуемого",
          });
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const itemController = new ItemController(
  new ItemService(),
  new NotificationService(),
  new ItemAdapter(),
  new LoadingController()
);
