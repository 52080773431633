import { createStore } from "@/shared/service/create-store";
import { StatisticsInitState } from "../schema/schema";


const initState: StatisticsInitState = {
  statisticsEventList: [],
  currentStatistic: null,
  currentId: null,
  currentCount: 0,
  loadingSubmit: false,
  totalCount: 0,
};

export const statisticsStore = createStore({ initState });

export type statisticsStoreType = typeof statisticsStore;