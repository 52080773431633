export const NAV = {
  ACCOUNT: "account",
  DASHBOARD: "dashboard",
  USER_INFO: "users/info/:id",
  USER_EDIT: "users/edit/:id",
  USER_ADD: "users/add",
  USER_LIST: "users",
  FEEDBACK_EDIT: "feedbacks/edit/:id",
  FEEDBACK_LIST: "feedbacks",
  EMAIL_TEMPLATES: "email-templates",
  EMAIL_SMTP: "email/smtp",
  EMAIL_HISTORY: "email-history",
  EMAIL_CREATE: "email-templates/create",
  EMAIL_EDIT: "email-templates/edit/:id",
  LOGS_LIST: "app-logs",
  APP_STATISTICS_EVENTS: "events",
  APP_STATISTICS_ADD: "events/add",
  APP_STATISTICS_EDIT: "events/edit/:id",
  LOGS: "logs",
  COMMON_SETTINGS: "common-settings",
  UPDATE_SYSTEM: "update-system",
  SETTINGS: "settings",
  LANGUAGE_LIST: "language/list",
  LANGUAGE_ADD: "language/add",
  LANGUAGE_EDIT: "language/edit/:id",
  PHRASE_LIST: "phrases/:languageId",
  PHRASE_ADD: "phrases/add/:languageId",
  PHRASE_EDIT: "phrases/edit/:id",
  CONFIGURATION_KEYS: "configuration/keys",
  CONFIGURATION_KEYS_ADD: "configuration/keys/add",
  CONFIGURATION_KEYS_EDIT: "configuration/keys/edit/:id",
  LOGIN: "login",
  MAIN: "/",
  NO_PAGE: "*",
  //------Template---------
  ITEM_LIST: "items",
  ITEM_ADD: "items/add",
  ITEM_EDIT: "items/edit/:id",
  ITEM_INFO: "items/info/:id",
  REGION_LIST: "regions",
  REGION_ADD: "regions/add",
  REGION_EDIT: "regions/edit/:id",
  CATEGORY_LIST: "category",
  CATEGORY_ADD: "category/add/:id",
  CATEGORY_EDIT: "category/edit/:id",
  REVIEWS_LIST: "reviews",
  REVIEWS_EDIT: "reviews/edit/:id",
  PRIVACY_INFO: "privacy",
  PRIVACY_EDIT: "privacy/edit",
  BONUS_LIST: "bonuses",
  BONUS_INFO: "bonuses/info/:id",
  BANNERS_LIST: "banners",
  BANNERS_ADD: "banners/add",
  BANNERS_EDIT: "banners/edit/:id",
  BANNERS_INFO: "banners/info/:id",
  ORDERS_LIST: "orders",
  ORDERS_INFO: "orders/info/:id",
  MANAGERS_LIST: "managers",
  MANAGERS_INFO: "managers/info/:id",
  MANAGERS_ADD: "managers/add",
  MANAGERS_EDIT: "managers/edit/:id",
  POLITIC: "policy-confidentiality",
  DOCUMENTS_LIST: "documents",
  DOCUMENTS_ADD: "documents/add",
  DOCUMENTS_EDIT: "documents/edit/:id",
  SOCIAL_SETTINGS: "social-settings",
};
