import { ManagerModel } from "../schema/schema";
import { managerStore, type ManagerStoreType } from "./store";

export class ManagerAdapter {
  public store: ManagerStoreType;
  constructor() {
    this.store = managerStore;
  }

  public setManagerList(managerList: ManagerModel[]) {
    return this.store.setState({ managerList });
  }

  public setCurrentManager(currentManager: ManagerModel) {
    return this.store.setState({ currentManager });
  }

  public filterById(id: string) {
    const prevList = this.store.getState().managerList;

    return this.store.setState({
      managerList: prevList.filter((item) => item.id !== Number(id)),
    });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ currentCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }
}
