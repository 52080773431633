import { FetchService } from "@/shared";
import { orderEndpoints, type OrderEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class OrderRepository {
  private readonly endpoints: OrderEndpointsType = orderEndpoints;

  constructor(private readonly fethService: FetchService) {}

  public fetchOrders(params: dto.OrdersParamsDto) {
    const url = this.endpoints.getList;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.OrdersResponseDto.parseAsync(data));
  }

  public fetchOrder(id: string) {
    const url = this.endpoints.getOne(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.CurrentOrderResponseDto.parseAsync(data));
  }

  public fetchCreateOrder(payload: any) {
    const url = this.endpoints.create;

    return this.fethService.post({ url, payload });
    // .then(data => dto.CurrentOrderResponseDto.parseAsync(data));
  }

  public fetchChangeStatusOrder(payload: { order_id: number; status: number }) {
    const url = this.endpoints.changeStatus;

    return this.fethService.put({ url, payload });
    // .then(data => dto.CurrentOrderResponseDto.parseAsync(data));
  }

  public fetchStatisticsDashboard(date: string) {
    const url = this.endpoints.getStatistics;

    return this.fethService.get({ url, params: { date } })
    .then(data => dto.OrdersStatisticsDashboardDto.parseAsync(data));
  }
}
