import { UserService } from "../service/service";
import { UserAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { getArrayRolesTranslate } from "@/shared/service/role-translate";
import * as dto from "../dto";

export class UserController {
  constructor(
    private readonly userService: UserService,
    private readonly notificationService: NotificationService,
    private readonly userAdapter: UserAdapter
  ) {}

  public setCurrentId(id: number) {
    return this.userAdapter.setCurrentId(id);
  }

  public resetCurrentId() {
    return this.userAdapter.resetCurrentId();
  }

  public getUsersList(payload: Record<string, string>) {
    this.userAdapter.setLoading();
    this.userService
      .getUsersList(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.userAdapter.setUsers(response.data.users);
          if (response.data.totalCount) {
            this.userAdapter.setCurrentCount(response.data.totalCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`, 
          })
        )
      )
      .finally(() => this.userAdapter.resetLoading());
  }

  public editUsers(payload: { id: string; payload: dto.EditUserPayloadDto }) {
    return new Promise((resolve) => {
      this.userAdapter.setLoadingSubmit();

      this.userService
        .editUser(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
             
            this.notificationService.activeNotification({
              status: "success",
              message: "Данные были успешно обновлены",
            });

            return resolve(response.status);
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`, 
            })
          )
        )
        .finally(() => this.userAdapter.resetLoadingSubmit());
    });
  }

  public editPasswordUsers(payload: { id: string; payload: dto.EditUserPasswordPayloadDto }) {
    return new Promise((resolve) => {
      this.userAdapter.setLoadingSubmit();

      this.userService
        .editPasswordUser(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Данные были успешно обновлены",
            });

            return resolve(response.status);
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`, 
            })
          )
        )
        .finally(() => this.userAdapter.resetLoadingSubmit());
    });
  }

  public removeUser(id: string) {
    return new Promise((resolve) => {
      if (!id) {
        return this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ID пользователя не найден`,
        });
      }

      this.userAdapter.setLoading();
      this.userService
        .removeUser(id)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.userAdapter.filterUsers(Number(id));
            this.notificationService.activeNotification({
              status: "success",
              message: "Пользователь успешно удален",
            });
            return resolve("success");
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`, 
            })
          )
        )
        .finally(() => this.userAdapter.resetLoading());
    });
  }

  public getRoles() {
    this.userAdapter.setLoading();
    this.userService
      .fetchRoles()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.userAdapter.setRoles(
            getArrayRolesTranslate(response.data.roles)
          );
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`, 
          })
        )
      )
      .finally(() => this.userAdapter.resetLoading());
  }

  public getCurrentUser(id: string) {
    return new Promise((resolve => {

      this.userAdapter.setLoading();
      this.userService
      .fethCurrentUser(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.userAdapter.setCurrentUser(response.data.user);
        } else {
          throw response.message;
        }
        return resolve(response.status);
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`, 
          })
          )
          )
          .finally(() => this.userAdapter.resetLoading());
    }))
  }

  public editUserAvatar(payload: { id: string; payload: FormData }) {
    return new Promise((res) => {
      this.userAdapter.setLoadingSubmit();
      this.userService
        .fethEditUserAvatar(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.userAdapter.setCurrentUser(response.data.user);
            this.notificationService.activeNotification({
              status: "success",
              message: "Данные были успешно обновлены",
            });
            if (response.data.user.avatar) {
              return res(response.data.user.avatar);
            }
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`, 
            })
          )
        )
        .finally(() => this.userAdapter.resetLoadingSubmit());
    });
  }

  public createUser(payload: dto.CreateUsersPayloadDto) {
    return new Promise((res) => {
      this.userAdapter.setLoadingSubmit();
      this.userService
        .fethCreateUser(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Пользователь успешно создан",
            });
            return res("success");
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`, 
            })
          )
        )
        .finally(() => this.userAdapter.resetLoadingSubmit());
    });
  }
}

export const userController = new UserController(
  new UserService(),
  new NotificationService(),
  new UserAdapter()
);
