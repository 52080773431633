import { FetchService } from "@/shared";
import { ManagerRepository } from "../repository/repository";
import * as dto from "../dto";

export class ManagerService {
  private readonly repository: ManagerRepository;

  constructor() {
    this.repository = new ManagerRepository(new FetchService());
  }

  public fethManagers(payload: dto.ManagersParamsDto) {
    return this.repository.fethManagers(payload);
  }

  public fethManager(id: string) {
    return this.repository.fethManager(id);
  }

  public fetchCreateManagers(payload: dto.CreateManagerDto) {
    return this.repository.fetchCreateManagers(payload);
  }

  public fethEditManager(payload: { id: string; payload: FormData }) {
    return this.repository.fethEditManager(payload);
  }

  public fethRemoveManagers(id: string) {
    return this.repository.fethRemoveManagers(id);
  }

  public fethAddManagerUser(payload: dto.AddManagerPayloadDto) {
    return this.repository.fethAddManagerUser(payload);
  }

  public fethRemoveManagerUser(id: string) {
    return this.repository.fethRemoveManagerUser(id);
  }
}
