export type OrderEndpointsType = {
  getList: string;
  create: string;
  getOne: (id: string) => string;
  changeStatus: string;
  getStatistics: string;
};

export const orderEndpoints: OrderEndpointsType = {
  getList: "accepted_order",
  create: "accepted_order",
  getOne: (id: string) => `accepted_order/${id}`,
  changeStatus: "order_history",
  getStatistics: "accepted_order/dashboard",
};
