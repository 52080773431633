import { ManagerSchema } from "@/entities/manager";
import { z } from "zod";

export const UserSchema = z.object({
  id: z.number(),
  username: z.string().nullable(),
  email: z.string().nullable(),
  avatar: z.string().nullable(),
  role: z.string().nullable(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),

  name: z.string().nullable().optional(),
  surname: z.string().optional().nullable(),
  patronymic: z.string().optional().nullable(),
  gender: z.boolean().nullable().or(z.number()),
  birthday: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  phone: z.string().optional().nullable(),

  manager: ManagerSchema.nullable().optional(),
  countShoppingCart: z.number().optional(),
  registration_type: z.string(),
  bonus_qr: z.string().nullable(),
  bonus_count: z.number().nullable(),
});

const UserInitStateSchema = z.object({
  users: z.array(UserSchema),
  currentUser: UserSchema.nullable(),
  currenUsertId: z.number().nullable(),
  loading: z.boolean(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
  roles: z.array(z.string()),
});

export type UserModel = z.infer<typeof UserSchema>;
export type UserInitState = z.infer<typeof UserInitStateSchema>;
