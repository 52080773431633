import { UserSchema } from "@/entities/users";
import { createResponseSchema } from "@/shared";
import { z } from "zod";

const ProfileResponseSchema = z.object({
  user: UserSchema,
});

const TokenSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
})

const AuthorizationResponseSchema = z.object({
  user: UserSchema,
  tokens: TokenSchema,
});

export const RefreshTokenResponseSchema = z.object({
  tokens: TokenSchema,
});

export const ProfileChangeAvatarResponseSchema = z.object({
  user: z.object({
    id: z.number(),
    username: z.string(),
    email: z.string(),
    role: z.string(),
    avatar: z.string(),
    created_at: z.string().nullable(),
    updated_at: z.string().nullable(),
  }),
});

export const ProfileChangeAvatarResponseDto = createResponseSchema(ProfileChangeAvatarResponseSchema);
export const ProfileResponseDto = createResponseSchema(ProfileResponseSchema);
export const AuthorizationResponseDto = createResponseSchema(AuthorizationResponseSchema);
export const RefreshTokenResponseDto = createResponseSchema(RefreshTokenResponseSchema);
