import { createStore } from "@/shared/service/create-store";
import { ReviewsInitState } from "../schema/schema";


const initState: ReviewsInitState = {
  reviewsList: [],
  currentReviews: null,
  loadingSubmit: false,
  currentCount: 0,
};

export const reviewsStore = createStore({ initState });

export type ReviewsStoreType = typeof reviewsStore;