import { z } from "zod";

export const PrivacySchema = z.object({
  id: z.number(),
  data: z.string(),
  date_update: z.string().nullable().optional(),
});

const PrivacyInitStateSchema = z.object({
  privacy: PrivacySchema.nullable(),
  loadingSubmit: z.boolean(),
});

export type PrivacyModel = z.infer<typeof PrivacySchema>;
export type PrivacyInitState = z.infer<typeof PrivacyInitStateSchema>;
