import { z } from "zod";

export const RegionSchema = z.object({
  id: z.number(),
  name: z.string(),
  coords: z.unknown().nullable(),
  date_create: z.string().nullable(),
  date_update: z.string().nullable(),
});

const RegionInitStateSchema = z.object({
  regionList: z.array(RegionSchema),
  currentRegion: RegionSchema.nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
});

export type RegionModel = z.infer<typeof RegionSchema>;
export type RegionInitState = z.infer<typeof RegionInitStateSchema>;
