import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { FeedbackSchema } from "../schema/schema";

const FeedbacksResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  feedbacks: z.array(FeedbackSchema),
});

const FeedbackResponseSchema = z.object({
  feedback: FeedbackSchema,
});

const FeedbackRemoveResponseSchema = z.object({
  message: z.string(),
});

export const FeedbacksResponseDto = createResponseSchema(
  FeedbacksResponseSchema
);
export const FeedbackResponseDto = createResponseSchema(FeedbackResponseSchema);
export const FeedbackRemoveResponseDto = createResponseSchema(
  FeedbackRemoveResponseSchema
);
