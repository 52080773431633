export type ReviewsEndpointsType = {
  create: string;
  getList: string;
  getOne: (id: string) => string;
  remove: (id: string) => string;
  edit: (id: string) => string;
};

export const reviewsEndpoints = {
  create: "items/feedback",
  getList: "items/feedback/all",
  getOne: (id: string) => `items/feedback/${id}`,
  remove: (id: string) => `items/feedback/${id}`,
  edit: (id: string) => `items/feedback/${id}`,
};
