import { z } from "zod";

export const CreateItemSchema = z.object({
  title: z.string().min(1, { message: "Введите название" }),

  brand: z.string().optional(),
  description_brand: z.string().optional(),
  product_line: z.string().optional(),
  purpose: z.string().optional(),
  description_general: z.string().optional(),
  description_use: z.string().optional(),
  description_compos: z.string().optional(),
  description_additional: z.string().optional(),

  category_id: z.number().min(1, { message: "Выберите категорию" }),
  in_stock: z.boolean(),
  price: z.number().min(1, { message: "Введите цену" }),
  price_discount: z.number().optional(),
  price_wholesaler: z.number().optional(),
  price_discount_wholesaler: z.number().optional(),
  description: z.string().optional(),
  description_units: z.string().min(1, { message: "Введите объем продукции" }),
  variable_units: z.string().min(1, { message: "Введите объем" }),
  regions: z.array(z.number()).optional(),
  isHit: z.boolean(),
});

export type CreateItemPayload = z.infer<typeof CreateItemSchema>;
