import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { OrderSchema, OrderStatisticSchema } from "../schema/schema";

const OrdersResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  orders: z.array(OrderSchema),
});



export const OrdersResponseDto = createResponseSchema(OrdersResponseSchema);
export const CurrentOrderResponseDto = createResponseSchema(OrderSchema);
export const OrdersStatisticsDashboardDto = createResponseSchema(z.array(OrderStatisticSchema));
