import { CategoryService } from "../service/service";
import { CategoryAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";
import { CategoryModel } from "../schema/schema";

class CategoryController {
  parrentCache: Map<string, CategoryModel[]>;

  constructor(
    private readonly categoryService: CategoryService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: CategoryAdapter,
    private readonly loadingController: LoadingController
  ) {
    this.parrentCache = new Map();
  }

  public getCategorys(params: dto.CategoryesParamsDto) {
    this.loadingController.activeFetchLoading();
    this.categoryService
      .fethCategoryes(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCategoryList(response.data.regions);

          if (response.data.currentCount) {
            this.storeAdapter.setTotalCount(response.data.currentCount);
          }

        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getCategory(id: string | undefined) {
    this.sendErrorGetID(id);
    if (!id) return;

    const storeCategory = this.storeAdapter.findCategory(id);

    if (storeCategory) {
      return this.storeAdapter.setCurrentCategory(storeCategory);
    }

    this.loadingController.activeFetchLoading();
    this.categoryService
      .fethCategory(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentCategory(response.data);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public createCategory(
    payload: dto.CreateCategoryDto
  ): Promise<dto.CurrentCategoryResponse> {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.categoryService
        .fetchCreateCategory(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Категория успешно добавлена",
            });
          } else {
            throw response.message;
          }
          return resolve(response);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public editCategory(payload: { id: string; payload: dto.CreateCategoryDto }) {
    return new Promise((resolve) => {
      this.sendErrorGetID(payload.id);
      if (!payload.id) return;

      this.storeAdapter.setLoadingSubmit();
      this.categoryService
        .fethEditCategory(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.setCurrentCategory(response.data);
            this.notificationService.activeNotification({
              status: "success",
              message: "Категория успешно обновлена",
            });
            return resolve(response.status);
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public removeCategory(id: string) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.storeAdapter.setLoadingSubmit();
    this.categoryService
      .fethRemoveCategory(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);

          this.notificationService.activeNotification({
            status: "success",
            message: "Категория успешно удалена",
          });
          this.getTreeCategory();
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public updateCategoryImage(payload: { id: string; file: File }) {
    return new Promise((resolve) => {
      this.sendErrorGetID(payload.id);
      if (!payload.id) return;

      this.categoryService
        .fethUpdateCategoryImage(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.setCurrentCategory(response.data);
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        );
    });
  }

  public getTreeCategory() {
    this.loadingController.activeFetchLoading();
    this.categoryService
      .fetchTree()
      .then((response) => {
        if (response.status === "success" && response.data) {

          this.storeAdapter.setCategoryList(response.data);

        } else {
          throw response.message;
        }

      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getParentCategory(id: string, level: 'level1' | 'level2') {

    const parentCache = this.parrentCache.get(id);

    if (parentCache && level === 'level1') {
    return this.storeAdapter.setParentCategory(parentCache)
    }

    if (parentCache && level === 'level2') {
    return this.storeAdapter.setSubParentCategory(parentCache)
    }

    if (level === 'level1') {
      this.storeAdapter.setParentLoading();
    } else if (level === 'level2') {
      this.storeAdapter.setSubParentLoading();
    }
    this.categoryService
      .fetchParentCategory(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          if (level === 'level1') {
            this.storeAdapter.setParentCategory(response.data);
          } else if (level === 'level2') {
            this.storeAdapter.setSubParentCategory(response.data)
          }
          this.parrentCache.set(id, response.data);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetParentLoading());
  }


  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const categoryController = new CategoryController(
  new CategoryService(),
  new NotificationService(),
  new CategoryAdapter(),
  new LoadingController()
);
