import { z } from "zod";

export const BonusSchema = z.object({
  id: z.number(),
  user_id: z.number().nullable(),
  accepted_id: z.number().nullable(),
  count: z.number(),
  isCrediting: z.number(),
  date_create: z.string().nullable(),
  date_crediting: z.string().nullable(),
});

const BonusInitStateSchema = z.object({
  bonusList: z.array(BonusSchema),
  currentBonus: BonusSchema.nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
});

export type BonusModel = z.infer<typeof BonusSchema>;
export type BonusInitState = z.infer<typeof BonusInitStateSchema>;
