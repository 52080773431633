export type CategoryEndpointsType = {
  create: string;
  getList: string;
  getTree: string;
  getParentCategory: (id: string) => string;
  getOne: (id: string) => string;
  remove: (id: string) => string;
  edit: (id: string) => string;
  title: (title: string) => string;
  editCategoryImage: (title: string) => string;
};

export const categoryEndpoints: CategoryEndpointsType = {
  create: "category",
  getList: "category",
  getTree: "category/tree",
  getParentCategory: (id: string) => `category/parent/${id}`,
  getOne: (id: string) => `category/${id}`,
  remove: (id: string) => `category/${id}`,
  edit: (id: string) => `category/${id}`,
  title: (title: string) => `regions/title/${title}`,
  editCategoryImage: (id: string) => `category/image/${id}`,
};
