import { FetchService } from "@/shared";
import { SystemRepository } from "../repository/repository";

export class SystemService {
  private readonly repository: SystemRepository;

  constructor() {
    this.repository = new SystemRepository(new FetchService());
  }

  public fethBackendExportZipName() {
    return this.repository.fethBackendExportZipName();
  }

  public fethBackendImport(file: File) {
    return this.repository.fethBackendImport(file);
  }
}
