export type EmailEndpointsType = {
  getHistorys: string;
  removeHistory: string;
  sendAllUser: string;
  sendEmail: (id: string) => string;
};

export const emailEndpoints: EmailEndpointsType = {
  getHistorys: "email/history",
  removeHistory: "email/history",
  sendEmail: (id: string) => `email/${id}`,
  sendAllUser: "email",
};
