import { createStore } from "@/shared/service/create-store";
import { ItemInitState } from "../schema/schema";


const initState: ItemInitState = {
  itemsList: [],
  currentItem: null,
  loadingSubmit: false,
  currentCount: 0,
  totalCount: 0,
};

export const itemStore = createStore({ initState });

export type itemStoreType = typeof itemStore;