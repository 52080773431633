import { RegionService } from "../service/service";
import { RegionAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

class RegionController {
  constructor(
    private readonly regionService: RegionService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: RegionAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getRegions(params: dto.RegionsParamsDto) {
    this.loadingController.activeFetchLoading();
    this.regionService
      .fethRegions(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setRegionsList(response.data.regions);

          if (response.data.currentCount) {
            this.storeAdapter.setTotalCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getRegion(id: string | undefined) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.loadingController.activeFetchLoading();
    this.regionService
      .fethRegion(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentRegion(response.data);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public createRegion(payload: dto.CreateRegionDto) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.regionService
        .fetchCreateRegion(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Регион успешно добавлен",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public editRegion(payload: { id: string; payload: dto.CreateRegionDto }) {
    return new Promise((resolve) => {
      this.sendErrorGetID(payload.id);
      if (!payload.id) return;

      this.storeAdapter.setLoadingSubmit();
      this.regionService
        .fethEditRegion(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.setCurrentRegion(response.data);
            this.notificationService.activeNotification({
              status: "success",
              message: "Регион успешно обновлен",
            });
            return resolve(response.status);
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public removeRegion(id: string) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.storeAdapter.setLoadingSubmit();
    this.regionService
      .fethRemoveRegion(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);

          this.notificationService.activeNotification({
            status: "success",
            message: "Регион успешно удален",
          });
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const regionController = new RegionController(
  new RegionService(),
  new NotificationService(),
  new RegionAdapter(),
  new LoadingController()
);
