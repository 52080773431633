import { BannerService } from "../service/service";
import { BannerAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

class BannerController {
  constructor(
    private readonly bannerService: BannerService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: BannerAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getBanners(params: dto.BannersParamsDto) {
    this.loadingController.activeFetchLoading();
    this.bannerService
      .fetchBanners(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setBannersList(response.data.items);
          if (response.data.currentCount) {
            this.storeAdapter.setTotalCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getBanner(id: string | undefined) {
    return new Promise((resolve) => {

      this.sendErrorGetID(id);
      if (!id) return;
      
      this.loadingController.activeFetchLoading();
      this.bannerService
      .fethBanner(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentBanner(response.data);
        } else {
          throw response.message;
        }
        return resolve(response.status)
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
    })
  }

  public createBanner(payload: dto.CreateBannerDto) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.bannerService
        .fetchCreateBanner(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Баннер успешно добавлен",
            });
          } else {
            throw response.message;
          }
          return resolve(response.data.id);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public editBanner(payload: { id: string; payload: dto.CreateBannerDto }) {
    return new Promise((resolve) => {
      this.sendErrorGetID(payload.id);
      if (!payload.id) return;

      this.storeAdapter.setLoadingSubmit();
      this.bannerService
        .fethEditBanners(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.setCurrentBanner(response.data);
            this.notificationService.activeNotification({
              status: "success",
              message: "Баннер успешно обновлен",
            });
            return resolve(response.status);
          } else {
            throw response.message;
          }
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public removeBanner(id: string) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.storeAdapter.setLoadingSubmit();
    this.bannerService
      .fethRemoveBanner(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);

          this.notificationService.activeNotification({
            status: "success",
            message: "Баннер успешно удален",
          });
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public updateBannerImage(payload: { id: string, file: File }) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.bannerService
      .fethUpdateImage(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentBanner(response.data);
          this.notificationService.activeNotification({
            status: "success",
            message: "Изображение успешно сохранено",
          });
        } else {
          throw response.message;
        }
        return resolve(response);
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
          )
          )
          .finally(() => this.storeAdapter.resetLoadingSumbmit());
    })
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const bannerController = new BannerController(
  new BannerService(),
  new NotificationService(),
  new BannerAdapter(),
  new LoadingController()
);
