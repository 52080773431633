import { FetchService } from "@/shared";
import { BonusRepository } from "../repository/repository";
import * as dto from "../dto";

export class BonusService {
  private readonly repository: BonusRepository;

  constructor() {
    this.repository = new BonusRepository(new FetchService());
  }

  public fethBonuses(params: dto.BonusParamsDto) {
    return this.repository.fethBonuses(params);
  }

  public fethBonus(id: string) {
    return this.repository.fethBonus(id);
  }

  public fetchUserBonus(payload: { id: string, params: dto.BonusParamsDto}) {
    return this.repository.fetchUserBonus(payload);
  }
}
