import { z } from "zod";

export const FrongendLogsParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
  id: z.string().optional(),
  name: z.string().optional(),
  message: z.string().optional(),
});

export const LogsParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
  id: z.string().optional(),
  status: z.string().optional(),
});

export const CreateFrontendLogsSchema = z.object({
  isFatal: z.boolean(),
  name: z.string().min(1, { message: "Введите имя" }),
  message: z.string().min(1, { message: "Введите сообщение" }),
});


export type FrongendLogsParamsDto = z.infer<typeof FrongendLogsParamsSchema>;
export type LogsParamsDto = z.infer<typeof LogsParamsSchema>;
export type CreateFrontendLogsDto = z.infer<typeof CreateFrontendLogsSchema>;
