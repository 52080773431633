import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { ItemSchema } from "../schema/schema";

const ItemsResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  items: z.array(ItemSchema),
});

const CreateItemRecomendResponseSchema = z.object({
  id: z.number(),
  item_id: z.number(),
  recomendation_id: z.number(),
});

export const ItemsResponseDto = createResponseSchema(ItemsResponseSchema);
export const CreateItemResponseSchemaDto = createResponseSchema(ItemSchema);
export const EdigImageItemResponseSchemaDto = createResponseSchema(ItemSchema);
export const RemoveItemResponseDto = createResponseSchema(ItemSchema);
export const ItemResponseDto = createResponseSchema(ItemSchema);
export const CreateItemRecomendResponseDto = createResponseSchema(
  CreateItemRecomendResponseSchema
);
