export type BannerEndpointsType = {
  create: string;
  getList: string;
  getOne: (id: string) => string;
  remove: (id: string) => string;
  edit: (id: string) => string;
  region: (regionId: string) => string;
  bannerImage: (regionId: string) => string;
};

export const bannersEndpoints: BannerEndpointsType = {
  create: "banners",
  getList: "banners",
  getOne: (id: string) => `banners/${id}`,
  remove: (id: string) => `banners/${id}`,
  edit: (id: string) => `banners/${id}`,
  region: (regionId: string) => `banners/region/${regionId}`,
  bannerImage: (id: string) => `banners/image/${id}`,
};
