import { createStore } from "@/shared/service/create-store";
import { SmtpInitState } from "../schema/schema";


const initState: SmtpInitState = {
  smtp: null,
  loadingSubmit: false,
};

export const smtpStore = createStore({ initState });

export type smtpStoreType = typeof smtpStore;