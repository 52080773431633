import { z } from "zod";

export const ReviewsSchema = z.object({
  id: z.number(),
  item_id: z.number(),
  rate: z.number(),
  name: z.string(),
  advantage: z.string().nullable(),
  disadvantage: z.string().nullable(),
  comment: z.string(),
  date_Create: z.string(),
});

const ReviewsInitStateSchema = z.object({
  reviewsList: z.array(ReviewsSchema),
  currentReviews: ReviewsSchema.nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
});

export type ReviewsModel = z.infer<typeof ReviewsSchema>;
export type ReviewsInitState = z.infer<typeof ReviewsInitStateSchema>;
