import { z } from "zod";

export const FrontendLogSchema = z.object({
  id: z.number(),
  creationDate: z.string(),
  isFatal: z.number(),
  message: z.string(),
  name: z.string(),
});

export const LogSchema = z.object({
  id: z.number(),
  error: z.unknown().nullable(),
  message: z.string().nullable(),
  status: z.string().nullable(),
  time: z.string(),
  body: z.object({
    id: z.number().optional(),
    iat: z.number().optional(),
    exp: z.number().optional(),
  }).or(z.string()).nullable(),
});

const LogsInitStateSchema = z.object({
  frontendLogsList: z.array(FrontendLogSchema),
  LogsList: z.array(LogSchema),
  loadingSubmit: z.boolean(),
  totalLogs: z.number(),
  totalFrontendLogs: z.number(),
});

export type FrontendLogModel = z.infer<typeof FrontendLogSchema>;
export type LogModel = z.infer<typeof LogSchema>;
export type LogsInitState = z.infer<typeof LogsInitStateSchema>;
