import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { RegionSchema } from "../schema/schema";

const RegionsResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  regions: z.array(RegionSchema),
});

export const RegionsResponseDto = createResponseSchema(RegionsResponseSchema);
export const CurrentRegionResponseDto = createResponseSchema(RegionSchema);
export const RemoveRegionResponseDto = createResponseSchema(z.number());
