import { z } from "zod";

export const EventSchema = z.object({
  id: z.number(),
  count: z.number().nullable().optional(),//
  isImportant: z.number(),
  name: z.string(),
});

const StatisticsInitSchema = z.object({
  statisticsEventList: z.array(EventSchema),
  currentStatistic: EventSchema.nullable(),
  currentId: z.number().nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
  totalCount: z.number(),
});

export type EventModel = z.infer<typeof EventSchema>;
export type StatisticsInitState = z.infer<typeof StatisticsInitSchema>;
