import { FetchService } from "@/shared";
import { PhraseRepository } from "../repository/repository";
import * as dto from "../dto";


export class PhraseService {
  private readonly repository: PhraseRepository;

  constructor() {
    this.repository = new PhraseRepository(new FetchService());
  }

  public fethPhrases(payload: dto.GetPhasesParamsDto) {
    return this.repository.fethPhrases(payload);
  }

  public fethPhrase(id: string) {
    return this.repository.fethPhrase(id);
  }

  public fetchCreatePhrase(payload: dto.CreatePhraseDto) {
    return this.repository.fetchCreatePhrase(payload);
  }

  public fethEditPhrase(payload: { id: string, payload: dto.EditPhaseDto }) {
    return this.repository.fethEditPhrase(payload);
  }

  public fethRemovePhrase(id: string) {
    return this.repository.fethRemovePhrase(id);
  }

  public fethTranslate(payload: dto.TranslatePayloadSchemaDto) {
    return this.repository.fethTranslate(payload);
  }

  public fethTranslates(payload: dto.TranslatesPayloadSchemaDto) {
    return this.repository.fethTranslates(payload);
  }
}
