import { createResponseSchema } from "@/shared";
import { UserSchema } from "../schema/schema";
import { z } from "zod";

const UsersResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  users: z.array(UserSchema),
});

const UserResponseSchema = z.object({
  user: UserSchema,
});


const RoleResponseSchema = z.object({
  roles: z.string().array().nonempty(),
});

export const UsersResponseDto = createResponseSchema(UsersResponseSchema);
export const UserResponseDto = createResponseSchema(UserResponseSchema);
export const UserEditResponseDto = createResponseSchema(UserSchema);
export const UserRemoveResponseDto = createResponseSchema(UserSchema);
export const RolesResponseDto = createResponseSchema(RoleResponseSchema);
