import { FetchService } from "@/shared";
import { bonusEndpoints, type BonusEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class BonusRepository {
  private readonly endpoints: BonusEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = bonusEndpoints;
  }

  public fethBonuses(params: dto.BonusParamsDto) {
    const url = this.endpoints.getList;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.BonusesResponseDto.parseAsync(data));
  }

  public fethBonus(id: string) {
    const url = this.endpoints.getOneBonus(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.BonusResponseDto.parseAsync(data));
  }

  public fetchUserBonus({ id, params}: { id: string, params: dto.BonusParamsDto}) {
    const url = this.endpoints.bonusUser(id);

    return this.fethService
      .get({ url, params })
      .then((data) => dto.BonusesResponseDto.parseAsync(data));
  }

}
