export type ItemEndpointsType = {
  create: string;
  getList: string;
  byFilter: string;
  preview: string;
  filter: string;
  forMain: string;
  getOne: (id: string) => string;
  edit: (id: string) => string;
  remove: (id: string) => string;
  title: (title: string) => string;
  category: (title: string) => string;
  editImage: (id: string) => string;
  removeImage: (id: string) => string;
  getOneAdmin: (id: string) => string;
  createRecomendation: string;
  removeRecomendation: (id: string) => string;
};

export const itemEndpoints: ItemEndpointsType = {
  create: "items",
  getList: "items",
  byFilter: "items/byFilter",
  preview: "items/preview",
  filter: "items/filter",
  forMain: "items/forMain",
  getOne: (id: string) => `items/${id}`,
  getOneAdmin: (id: string) => `items/admin/${id}`,
  edit: (id: string) => `items/${id}`,
  remove: (id: string) => `items/${id}`,
  title: (title: string) => `items/title/${title}`,
  category: (title: string) => `items/category/${title}`,
  editImage: (id: string) => `items/image/${id}`,
  removeImage: (id: string) => `items/image/${id}`,
  createRecomendation: "items/recomendation",
  removeRecomendation: (id: string) => `items/recomendation/${id}`,
};
