const BeforeLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100vw",
        height: "150%",
      }}
    >
      <div
        style={{
          padding: 1,
          backgroundColor: "#5664d2",
          height: 50,
          width: "100%",
        }}
      ></div>

      <div style={{ display: "flex", width: "100%", height: "100%" }}>
        <div
          style={{
            padding: 1,
            backgroundColor: "white",
            width: 256,
            height: "100%",
          }}
        ></div>
        <div
          style={{
            padding: 1,
            backgroundColor: "#f4f6f8",
            height: "100%",
            width: "100%",
          }}
        ></div>
      </div>
    </div>
  );
};

export { BeforeLoading };
