import { z } from "zod";

export const CategoryesParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
});

export const CreateCategorySchema = z.object({
  title: z.string().min(1, { message: "Введите название" }),
  parent_category: z.number().nullable(),
});

export type CategoryesParamsDto = z.infer<typeof CategoryesParamsSchema>;
export type CreateCategoryDto = z.infer<typeof CreateCategorySchema>;
