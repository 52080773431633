import { UserModel } from "@/entities/users";
import { authStore, authStoreType } from "./store";


export class AuthAdapter {
  public store: authStoreType;
  constructor() {
    this.store = authStore;
  }

  public setProfile(profile: UserModel) {
    return this.store.setState({ profile, authorization: true });
  }

  public setProfileAvatar(avatar: string) {
    const currentProfile = this.store.getState().profile;
//@ts-ignore
    return this.store.setState({ profile: {...currentProfile, avatar} });
  }

  public logout() {
    return this.store.setState({ profile: null, authorization: false });
  }
 
  public setLoading() {
    return this.store.setState({ loading: true });
  }

  public resetLoading() {
    return this.store.setState({ loading: false });
  }

}


