export type UserEndpointsType = {
  getUsers: string;
  getRoles: string;
  createUser: string;
  removeUser: (id: string) => string;
  getUser: (id: string) => string;
  editUserAvatar: (id: string) => string;
  editUserInfo: (id: string) => string;
  editUserPassword: (id: string) => string;
};

export const userEndpoints: UserEndpointsType = {
  getUsers: "admin/users",
  getRoles: "roles",
  createUser: "admin/users",
  removeUser: (id: string) => `admin/users/${id}`,
  getUser: (id: string) => `admin/users/${id}`,
  editUserAvatar: (id: string) => `admin/users/avatar/${id}`,
  editUserInfo: (id: string) => `admin/users/${id}`,
  editUserPassword: (id: string) => `admin/users/password/${id}`,
};
