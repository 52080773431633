import { OrderModel, OrderStatisticModel } from "../schema/schema";
import { orderStore, type OrderStoreType } from "./store";


export class OrderAdapter {
  public store: OrderStoreType
  constructor() {
    this.store = orderStore;
  }

  public setOrdersList(orders: OrderModel[]) {
    return this.store.setState({ ordersList: orders });
  }

  public setOrderStatistics(statistics: OrderStatisticModel[]) {
    return this.store.setState({ statistics });
  }

  public setCurrentOrder(order: OrderModel) {
    return this.store.setState({ currentOrder: order });
  }

  public filterById(id: string) {
    const prevList = this.store.getState().ordersList;

    return this.store.setState({ ordersList: prevList.filter(item => item.id !== Number(id)) });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ currentCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

}


