import { LogsAdapter } from "../store/adapter";
import { LogsService } from "../service/service";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

export class LogsController {
  constructor(
    private readonly logsService: LogsService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: LogsAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getFrontendLogs(params: dto.FrongendLogsParamsDto) {
    this.loadingController.activeFetchLoading();
    this.logsService
      .fetchFrontendLogs(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setFrontendLogsList(response.data.logs);

          if (response.data.currentCount) {
            this.storeAdapter.setFrontendLogTotalCount(
              response.data.currentCount
            );
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public createFrontendLog(payload: dto.CreateFrontendLogsDto) {
    const validPayload = dto.CreateFrontendLogsSchema.safeParse(payload);

    if (!validPayload.success) {
      const message = validPayload.error.message
        ? validPayload.error.message
        : "Ошибка создания лога";
      console.error(message);
      return this.notificationService.activeNotification({
        status: "error",
        message: message,
      });
    }

    this.logsService
      .fetchCreateFrontendLog(validPayload.data)
      .then((response) => {
        if (response.status === "success" && response.data) {
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      );
  }

  public removeFrontendLogs(): Promise<string> {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.logsService
        .fetchRemoveFrontendLog()
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.resetFrontendLogs();
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public getLogs(params: dto.LogsParamsDto) {
    this.loadingController.activeFetchLoading();
    this.logsService
      .fetchLogs(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setLogsList(response.data.logs);

          if (response.data.currentCount) {
            this.storeAdapter.setLogsTotalCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public removeLogs(): Promise<string> {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.logsService
        .fetchRemoveLogs()
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.resetLogs();
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }
}

export const logsController = new LogsController(
  new LogsService(),
  new NotificationService(),
  new LogsAdapter(),
  new LoadingController()
);
