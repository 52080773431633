import { ItemModel } from "../schema/schema";
import { itemStore, type itemStoreType } from "./store";

export class ItemAdapter {
  public store: itemStoreType;
  constructor() {
    this.store = itemStore;
  }

  public setItemsList(items: ItemModel[]) {
    return this.store.setState({ itemsList: items });
  }

  public setCurrentItem(language: ItemModel) {
    return this.store.setState({ currentItem: language });
  }

  public filterById(id: string) {
    const prevList = this.store.getState().itemsList;

    return this.store.setState({
      itemsList: prevList.filter((item) => item.id !== Number(id)),
    });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ totalCount: count });
  }

  public setCurrenCount(count: number) {
    return this.store.setState({ currentCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

  public filterRecomendById(id: string) {
    const currentItem = this.store.getState().currentItem;
    if (!currentItem) return;
    return this.store.setState({
      currentItem: {
        ...currentItem,
        recomendation: currentItem.recomendation?.filter(
          (item) => item.id !== Number(id)
        ),
      },
    });
  }
}
