import { FetchService } from "@/shared";
import { UserRepository } from "../repository/repository";
import * as dto from "../dto";

export class UserService {
  private readonly repository: UserRepository;

  constructor() {
    this.repository = new UserRepository(new FetchService());
  }

  public getUsersList(payload: Record<string, string>) {
    return this.repository.getUsersList(payload);
  }

  public editUser(payload: { id: string; payload: dto.EditUserPayloadDto }) {
    return this.repository.fethEditUser(payload);
  }

  public editPasswordUser(payload: { id: string; payload: dto.EditUserPasswordPayloadDto }) {
    return this.repository.fethEditPassword(payload);
  }

  public removeUser(id: string) {
    return this.repository.removeUser(id);
  }

  public fetchRoles() {
    return this.repository.fethRoles();
  }

  public fethCurrentUser(id: string) {
    return this.repository.fethCurrentUser(id);
  }

  public fethEditUserAvatar(payload: { id: string; payload: FormData }) {
    return this.repository.fethEditUserAvatar(payload);
  }

  public fethCreateUser(payload: dto.CreateUsersPayloadDto) {
    return this.repository.fethCreateUser(payload);
  }
}
