import { BonusModel } from "../schema/schema";
import { bonusStore, type BonusStoreType } from "./store";

export class BonusAdapter {
  public store: BonusStoreType;
  constructor() {
    this.store = bonusStore;
  }

  public setBonusList(bonuses: BonusModel[]) {
    return this.store.setState({ bonusList: bonuses });
  }

  public setCurrentBonus(bonus: BonusModel) {
    return this.store.setState({ currentBonus: bonus });
  }

  public filterById(id: string) {
    const prevList = this.store.getState().bonusList;

    return this.store.setState({
      bonusList: prevList.filter((item) => item.id !== Number(id)),
    });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ currentCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }
}
