import { createStore } from "@/shared/service/create-store";
import { BonusInitState } from "../schema/schema";

const initState: BonusInitState = {
  bonusList: [],
  currentBonus: null,
  loadingSubmit: false,
  currentCount: 0,
};

export const bonusStore = createStore({ initState });

export type BonusStoreType = typeof bonusStore;
