import { createStore } from "@/shared/service/create-store";
import { OrderInitState } from "../schema/schema";


const initState: OrderInitState = {
  ordersList: [],
  currentOrder: null,
  loadingSubmit: false,
  currentCount: 0,
  statistics: [],
};

export const orderStore = createStore({ initState });

export type OrderStoreType = typeof orderStore;