import { z } from "zod";

export const BonusParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
});



export type BonusParamsDto = z.infer<typeof BonusParamsSchema>;
