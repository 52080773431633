import { BonusService } from "../service/service";
import { BonusAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

class BonusController {
  constructor(
    private readonly bonusService: BonusService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: BonusAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getBonuses(params: dto.BonusParamsDto) {
    this.loadingController.activeFetchLoading();
    this.bonusService
      .fethBonuses(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setBonusList(response.data.items);

          if (response.data.currentCount) {
            this.storeAdapter.setTotalCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getBonus(id: string | undefined) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.loadingController.activeFetchLoading();
    this.bonusService
      .fethBonus(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentBonus(response.data);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getUserBonuses(payload: { id: string; params: dto.BonusParamsDto }) {
    return new Promise((resolve) => {
      this.loadingController.activeFetchLoading();
      this.bonusService
        .fetchUserBonus(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.setBonusList(response.data.items);

            if (response.data.currentCount) {
              this.storeAdapter.setTotalCount(response.data.currentCount);
            }
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.loadingController.resetFethLoading());
    });
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const bonusController = new BonusController(
  new BonusService(),
  new NotificationService(),
  new BonusAdapter(),
  new LoadingController()
);
