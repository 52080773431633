export type RegionsEndpointsType = {
  create: string;
  getList: string;
  getOne: (id: string) => string;
  remove: (id: string) => string;
  edit: (id: string) => string;
  name: (name: string) => string;
  coords: (name: string) => string;
};

export const regionEndpoints = {
  create: "regions",
  getList: "regions",
  getOne: (id: string) => `regions/${id}`,
  remove: (id: string) => `regions/${id}`,
  edit: (id: string) => `regions/${id}`,
  name: (name: string) => `regions/name/${name}`,
  coords: (name: string) => `regions/coords/${name}`,
};
