import { z } from "zod";

export const GetPhasesParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
  languageId: z.string().optional(),
});

export const EditPhaseSchema = z.object({
  phrase: z.string().min(1, { message: "Введите фразу" }),
  translatedPhrase: z.string().min(1, { message: "Введите перевод" }),
  languageId: z.number({ invalid_type_error: 'ID  должно быть числом' }),
});

export const CreatePhraseSchema = EditPhaseSchema.extend({});

export const TranslatePayloadSchema = z.object({
  phrase: z.string().min(1),
  languageId: z.number({ invalid_type_error: 'ID  должно быть числом' }),
});

export const TranslatesPayloadSchema = z.object({
  phrases: z.array(z.string()).nonempty(),
  languageId: z.number({ invalid_type_error: 'ID  должно быть числом' }),
});

export type GetPhasesParamsDto = z.infer<typeof GetPhasesParamsSchema>;
export type EditPhaseDto = z.infer<typeof EditPhaseSchema>;
export type CreatePhraseDto = z.infer<typeof CreatePhraseSchema>;
export type TranslatePayloadSchemaDto = z.infer<typeof TranslatePayloadSchema>;
export type TranslatesPayloadSchemaDto = z.infer<typeof TranslatesPayloadSchema>;
