import { Loader } from "@/shared";
import { loadingStore } from "@/shared/service/loading";

import styles from "./LoadingOverlay.module.scss";

const LoadingOverlay = () => {
  const fetchLoading = loadingStore((state) => state.fetchloading);

  if (!fetchLoading) return null;

  return (
    <section className={styles.root}>
      <Loader />
    </section>
  );
};

export { LoadingOverlay };
