import { CategoryModel } from "../schema/schema";
import { categoryStore, CategoryStoreType } from "./store";


export class CategoryAdapter {
  public store: CategoryStoreType
  constructor() {
    this.store = categoryStore;
  }

  public setCategoryList(categorys: CategoryModel[]) {
    return this.store.setState({ categoryList: categorys });
  }

  public setCurrentCategory(category: CategoryModel) {
    return this.store.setState({ currentCategory: category });
  }

  public filterById(id: string) {
    const prevList = this.store.getState().categoryList;

    return this.store.setState({ categoryList: prevList.filter(item => item.id !== Number(id)) });
  }

  public findCategory(id: string) {
    return this.store.getState().categoryList.find(item => item.id === Number(id));
  }

  public setTotalCount(count: number) {
    return this.store.setState({ currentCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

  public setParentCategory(categorys: CategoryModel[]) {
    return this.store.setState({ parentCategory: categorys });
  }

  public setSubParentCategory(categorys: CategoryModel[]) {
    return this.store.setState({ subParrentCategorys: categorys });
  }

  public setParentLoading() {
    return this.store.setState({ parentLoading: true });
  }

  public setSubParentLoading() {
    return this.store.setState({ subParentLoading: true });
  }

  public resetParentLoading() {
    return this.store.setState({ parentLoading: false, subParentLoading: false });
  }

}


